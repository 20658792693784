import { round } from 'lodash-es';
import { NumericFormat, NumericFormatProps, numericFormatter } from 'react-number-format';

export type NumberTextProps = { value?: number; precision?: number; na?: string };

export const toFixed = (value: number, precision: number): string => {
  return round(value, precision).toFixed(precision);
};

export const formatNumber = (
  value = 0,
  precision = 4,
  na?: string,
  props: NumericFormatProps & { na?: string } = { thousandSeparator: true }
): string => {
  if (!value && na) {
    return na;
  }
  return numericFormatter(toFixed(value, precision), props);
};

export const NumberText = ({ value, precision, na }: NumberTextProps) => {
  if (!value && na) {
    return <>{na}</>;
  }
  return (
    <NumericFormat
      displayType="text"
      thousandSeparator=","
      value={precision === undefined || value === undefined ? value : toFixed(value, precision)}
    />
  );
};

export default NumberText;
