import axios, { AxiosError, AxiosInstance } from 'axios';
import { isObject } from 'lodash-es';
import { useNavigate } from 'react-router';
import { build } from 'typage-url';
import { SERVER_URL } from '~/config';
import { useSession } from '~/core/session/hooks';
import { isNotEmptyFormValue } from '~/lib/jsTools/predicate';
import { $ } from '~/routes/path';

const paramsSerializer = (params: any) => {
  const keys = Object.keys(params);
  let options = '';

  keys.forEach((key) => {
    const isParamTypeObject = isObject(params[key]);
    const isParamTypeArray = Array.isArray(params[key]);

    if (!isParamTypeObject && isNotEmptyFormValue(params[key])) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].filter(isNotEmptyFormValue).forEach((it: any) => {
        options += `${key}=${it}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};

export function useHttpClient(baseUrl = ''): AxiosInstance {
  const session = useSession();
  const navigate = useNavigate();

  const instance = axios.create({
    baseURL: `${SERVER_URL}${baseUrl}`,
    paramsSerializer
  });

  instance.interceptors.request.use(async (config) => {
    if (!session) {
      return navigate(build($.login));
    }
    try {
      const accessToken = await session.getAccessToken();
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`
        }
      };
    } catch {
      return navigate(build($.login));
    }
  });

  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 403) {
        navigate(build($.accessDenied));
      }
      if (error.response?.status === 404) {
        navigate(build($.notFound));
      }
      return Promise.reject(error);
    }
  );

  return instance;
}
