import { isNumber, round, sum, sumBy } from 'lodash-es';
import { DAYS_PER_YEAR, MONTHS_PER_YEAR } from '~/constants/date';
import { date, isDateBetween, isPastDate } from '~/lib/date';
import { safeSum } from '~/lib/jsTools/number';
import { BaseEntity } from '~/lib/model/base';
import { calculateDailyEnergy, calculateDailyPriceByFeePeriod } from '~/models/common';
import { User } from '~/models/user';
import { Company, ICompanyOutlineResponse } from './company';
import { ICouncilOutlineResponse } from './council';
import { FeePeriod, IRetailer, IRetailerOutlineResponse } from './retailer';

export enum EnergyType {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS'
}

export enum MeterType {
  NMI = 'NMI',
  MIRN = 'MIRN'
}

export enum MeterRefType {
  Company = 'Company',
  Council = 'Council'
}

export enum NMIMeterType {
  // TODO: Basic/MRIM
  BASIC_MRIM = 'BASIC_MRIM',
  COMMS4 = 'COMMS4'
}

export enum MIRNMeterType {
  BASIC = 'BASIC',
  INTERVAL = 'INTERVAL'
}

export enum SourceOfEstConsumption {
  INTERVAL_DATA = 'INTERVAL_DATA',
  INVOICE = 'INVOICE',
  CUSTOMER_ESTIMATE = 'CUSTOMER_ESTIMATE'
}

export enum SiteClassification {
  C_I = 'C&I',
  TARIFF = 'TARIFF'
}

export enum ContractCategory {
  SINGLE_SITE = 'SINGLE_SITE',
  MULTISITE = 'MULTISITE'
}

export enum DMAContractAdditionalServiceType {
  BILL_VALIDATION = 'BILL_VALIDATION',
  FINANCIAL_REPORT = 'FINANCIAL_REPORT',
  USAGE = 'USAGE',
  NETWORK_TARIFF_REVIEW = 'NETWORK_TARIFF_REVIEW',
  CO2_EMISSIONS = 'CO2_EMISSIONS',
  TTEG_PORTAL = 'TTEG_PORTAL',
  VALUE_PACKAGE = 'VALUE_PACKAGE'
}

export interface IMeter {
  id: string;
  energyType: EnergyType;
  meterNumber: string;
  meterType: NMIMeterType | MIRNMeterType;
  streetAddress: string;
  suburb: string;
  state: string;
  postCode: string;
  refType?: MeterRefType;
  refId?: string;
  siteClassification: SiteClassification;
  sourceOfEstConsumption?: SourceOfEstConsumption;
  invoiceBillingPeriodFromDate?: string;
  invoiceBillingPeriodToDate?: string;
  invoiceIssueDate?: string;
  networkDistributor?: string;
  networkTariffCode?: string;
  networkTariffDesc?: string;
  description?: string;
  checkSum?: number;
}

export interface NetworkChargeStanding {
  item: string;
  usage?: number;
  unit?: FeePeriod;
  unitPrice: number;
}

export interface NetworkChargeEnergy {
  item: string;
  usage?: number;
  unit?: FeePeriod;
  unitPrice: number;
}

export interface NetworkChargeDemand {
  item: string;
  usage?: number;
  unit?: FeePeriod;
  unitPrice: number;
  monthCount: number;
}

export interface IElectricity {
  numOfMeters: number;
  dlf?: number;
  mlf?: number;
  tlf?: number;
  installedSolarCapacity?: number;
  annualEstPeakEnergy?: number;
  annualEstShoulderEnergy?: number;
  annualEstOffPeakEnergy?: number;
  loadFactor?: number;
  powerFactor?: number;
  maxDemand?: number;
  contracts?: INMIContract[];
  feedInTariff?: string;
  participantCharge?: number | null;
  ancillaryCharge?: number | null;
  networkChargesStanding?: NetworkChargeStanding[];
  networkChargesEnergy?: NetworkChargeEnergy[];
  networkChargesDemand?: NetworkChargeDemand[];
}

export interface INMI extends IMeter, IElectricity {
  meterType: NMIMeterType;
}

export type INMICreateRequest = Pick<
  INMI,
  | 'meterNumber'
  | 'meterType'
  | 'streetAddress'
  | 'suburb'
  | 'state'
  | 'postCode'
  | 'sourceOfEstConsumption'
  | 'invoiceBillingPeriodFromDate'
  | 'invoiceBillingPeriodToDate'
  | 'invoiceIssueDate'
  | 'networkDistributor'
  | 'networkTariffCode'
  | 'networkTariffDesc'
  | 'siteClassification'
  | 'description'
  | 'numOfMeters'
  | 'dlf'
  | 'mlf'
  | 'tlf'
  | 'installedSolarCapacity'
  | 'annualEstPeakEnergy'
  | 'annualEstShoulderEnergy'
  | 'annualEstOffPeakEnergy'
  | 'loadFactor'
  | 'powerFactor'
  | 'maxDemand'
  | 'refType'
  | 'refId'
  | 'feedInTariff'
  | 'participantCharge'
  | 'ancillaryCharge'
  | 'networkChargesStanding'
  | 'networkChargesEnergy'
  | 'networkChargesDemand'
>;

export type INMIUpdateRequest = INMICreateRequest;

export interface IGas {
  uafg?: number;
  annualContractQuantity?: number;
  mdq?: number;
  mhq?: number;
  networkMaximumDailyQuantity?: number;
  loadFactor?: number;
  distributionZone?: string;
}

export interface IMIRN extends IMeter, IGas {
  meterType: MIRNMeterType;
}

export type IMIRNCreateRequest = Pick<
  IMIRN,
  | 'meterNumber'
  | 'meterType'
  | 'streetAddress'
  | 'suburb'
  | 'state'
  | 'postCode'
  | 'sourceOfEstConsumption'
  | 'invoiceBillingPeriodFromDate'
  | 'invoiceBillingPeriodToDate'
  | 'invoiceIssueDate'
  | 'networkDistributor'
  | 'networkTariffCode'
  | 'networkTariffDesc'
  | 'siteClassification'
  | 'description'
  | 'uafg'
  | 'annualContractQuantity'
  | 'mdq'
  | 'mhq'
  | 'networkMaximumDailyQuantity'
  | 'loadFactor'
  | 'distributionZone'
  | 'refType'
  | 'refId'
>;

export type IMIRNUpdateRequest = IMIRNCreateRequest;

export class NMI extends BaseEntity implements INMI {
  id!: string;
  energyType!: EnergyType;
  meterNumber!: string;
  meterType!: NMIMeterType;
  streetAddress!: string;
  suburb!: string;
  state!: string;
  postCode!: string;
  refType?: MeterRefType;
  refId?: string;
  siteClassification!: SiteClassification;
  sourceOfEstConsumption?: SourceOfEstConsumption;
  invoiceBillingPeriodFromDate?: string;
  invoiceBillingPeriodToDate?: string;
  invoiceIssueDate?: string;
  networkDistributor?: string;
  networkTariffCode?: string;
  networkTariffDesc?: string;
  description?: string;
  numOfMeters!: number;
  dlf?: number;
  mlf?: number;
  tlf?: number;
  installedSolarCapacity?: number;
  annualEstPeakEnergy?: number;
  annualEstShoulderEnergy?: number;
  annualEstOffPeakEnergy?: number;
  loadFactor?: number;
  powerFactor?: number;
  maxDemand?: number;
  checkSum?: number;
  contracts?: NMIContract[];
  dmaContracts?: DMAContract[];
  feedInTariff?: string;
  activateContractNum?: number | null;
  participantCharge?: number | null;
  ancillaryCharge?: number | null;
  networkChargesStanding?: NetworkChargeStanding[];
  networkChargesEnergy?: NetworkChargeEnergy[];
  networkChargesDemand?: NetworkChargeDemand[];
  company?: Company;
  latestContract?: NMIContract;

  static override from<T = NMI>(raw: any): T {
    const parse = (value: any) => (Array.isArray(value) ? value : JSON.parse(value || '[]'));
    return Object.assign(new NMI(), {
      ...raw,
      networkChargesStanding: parse(raw.networkChargesStanding),
      networkChargesEnergy: parse(raw.networkChargesEnergy),
      networkChargesDemand: parse(raw.networkChargesDemand),
      contracts: raw.contracts?.map((contract: any) => NMIContract.from(contract)),
      dmaContracts: raw.dmaContracts?.map((contract: any) => DMAContract.from(contract)),
      company: Company.from(raw.company),
      annualEstPeakEnergy: raw.annualEstPeakEnergy ?? 0,
      annualEstShoulderEnergy: raw.annualEstShoulderEnergy ?? 0,
      annualEstOffPeakEnergy: raw.annualEstOffPeakEnergy ?? 0
    });
  }

  get annualEstTotalEnergy(): number {
    return sum([this.annualEstOffPeakEnergy, this.annualEstPeakEnergy, this.annualEstShoulderEnergy]);
  }

  get dailyEstPeakEnergy(): number {
    return calculateDailyEnergy(this.annualEstPeakEnergy);
  }

  get dailyEstOffPeakEnergy(): number {
    return calculateDailyEnergy(this.annualEstOffPeakEnergy);
  }

  get dailyEstShoulderEnergy(): number {
    return calculateDailyEnergy(this.annualEstShoulderEnergy);
  }

  get dailyEstTotalEnergy(): number {
    return sum([this.dailyEstPeakEnergy, this.dailyEstOffPeakEnergy, this.dailyEstShoulderEnergy]);
  }

  get activeContract(): NMIContract | undefined {
    return this.contracts?.find((contract) =>
      isDateBetween(date(), contract.contractStartDate, contract.contractEndDate)
    );
  }

  get activeDMAContract(): DMAContract | undefined {
    return this.dmaContracts?.find((contract) =>
      isDateBetween(date(), contract.contractStartDate, contract.contractEndDate)
    );
  }

  get activeContracts(): NMIContract[] {
    return (
      this.contracts?.filter((contract) =>
        isDateBetween(date(), contract.contractStartDate, contract.contractEndDate)
      ) ?? []
    );
  }

  calculateTotalEnergy(): number {
    const { annualEstOffPeakEnergy, annualEstShoulderEnergy, annualEstPeakEnergy } = this;
    return safeSum(annualEstOffPeakEnergy, annualEstShoulderEnergy, annualEstPeakEnergy);
  }

  calculateLoadFactor(): number {
    const { annualEstOffPeakEnergy, annualEstShoulderEnergy, annualEstPeakEnergy } = this;
    if (!isNumber(annualEstPeakEnergy)) {
      return 0;
    }
    return round(
      safeSum(annualEstOffPeakEnergy, annualEstShoulderEnergy, annualEstPeakEnergy) /
        (annualEstPeakEnergy * (DAYS_PER_YEAR / MONTHS_PER_YEAR) * 24),
      2
    );
  }

  calculateAnnualNetworkStanding(): number {
    return sumBy(this.networkChargesStanding, (charge) => {
      const unitPrice = charge.unitPrice ?? 0;
      if (charge.unit === FeePeriod.MONTHLY) {
        return unitPrice * MONTHS_PER_YEAR;
      }
      if (charge.unit === FeePeriod.DAILY) {
        return unitPrice * DAYS_PER_YEAR;
      }
      return unitPrice;
    });
  }

  calculateAnnualNetworkChargesEnergy(totalEnergy?: number): number {
    return sumBy(this.networkChargesEnergy, (charge) => {
      return (
        ((charge.unitPrice / 100) * (charge.usage ?? 1) * (totalEnergy || this.annualEstTotalEnergy)) /
        sumBy(this.networkChargesEnergy, 'usage')
      );
    });
  }

  calculateAnnualNetworkChargesDemand(): number {
    return sumBy(this.networkChargesDemand, (charge) => {
      const unitPrice = charge.unitPrice ?? 0;
      const usage = charge.usage ?? 1;
      if (charge.unit === FeePeriod.MONTHLY) {
        return unitPrice * usage * MONTHS_PER_YEAR;
      }
      if (charge.unit === FeePeriod.DAILY) {
        return unitPrice * usage * DAYS_PER_YEAR;
      }
      return unitPrice * usage;
    });
  }

  calculateAnnualMarketCharge(): number {
    return (
      (sum([this.ancillaryCharge, this.participantCharge]) * this.annualEstTotalEnergy * (this.dlf || this.tlf || 1)) /
      100
    );
  }
}

export class MIRN extends BaseEntity implements IMIRN {
  id!: string;
  energyType!: EnergyType;
  meterNumber!: string;
  meterType!: MIRNMeterType;
  streetAddress!: string;
  suburb!: string;
  state!: string;
  postCode!: string;
  siteClassification!: SiteClassification;
  sourceOfEstConsumption?: SourceOfEstConsumption;
  invoiceBillingPeriodFromDate?: string;
  invoiceBillingPeriodToDate?: string;
  invoiceIssueDate?: string;
  networkDistributor?: string;
  networkTariffCode?: string;
  networkTariffDesc?: string;
  description?: string;
  uafg?: number;
  totalEnergy?: number;
  mdq?: number;
  mhq?: number;
  networkMaximumDailyQuantity?: number;
  loadFactor?: number;
  distributionZone?: string;
  checkSum?: number;
  contracts?: MIRNContract[];
  annualContractQuantity?: number;
  activateContractNum?: number | null;
  company?: Company;
  latestContract?: MIRNContract;

  calculateLoadFactor(): number {
    const { annualContractQuantity, mdq } = this;
    if (!isNumber(annualContractQuantity) || !isNumber(mdq)) {
      return 0;
    }
    return annualContractQuantity / 12 / (mdq * (365 / 12));
  }

  get activeContract(): IMIRNContract | undefined {
    return this.contracts?.find((contract) =>
      isDateBetween(date(), contract.contractStartDate, contract.contractEndDate)
    );
  }

  get activeContracts(): IMIRNContract[] {
    return (
      this.contracts?.filter((contract) =>
        isDateBetween(date(), contract.contractStartDate, contract.contractEndDate)
      ) ?? []
    );
  }
}

export type Meter = NMI | MIRN;

export interface IContractPeriodCreateRequest {
  periodFromDate: string;
  periodToDate: string;
  peakRate?: number;
  shoulderRate?: number;
  offPeakRate?: number;
  lretRate?: number;
  srecRate?: number;
  veetRate?: number;
  essRate?: number;
  aeeisRate?: number;
  commodityRate?: number;
  fixedTuosCharge?: number;
}

export interface ISingleContractPeriodCreateRequest extends IContractPeriodCreateRequest {
  contractId: string;
}

export interface IContractPeriod extends IContractPeriodCreateRequest {
  id: string;
}

export type IContractPeriodUpdateRequest = IContractPeriodCreateRequest;

export interface INMIContractCreateRequest {
  meterId: string;
  retailerId: string;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  retailSupplyCharge?: number;
  bundleDemandCharge?: number;
  fixedRetailTariffDiscount?: number;
  retailTariffDesc?: string;
  specialConditions?: string;
  ttegCommissionOnUsage?: number;
  ttegCommissionOnMetering?: number;
  createContractPeriodRequests?: IContractPeriodCreateRequest[];
  contractPeakEnergy?: number;
  contractShoulderEnergy?: number;
  contractOffPeakEnergy?: number;
  solarArrangementDesc?: string;
  accreditedGreenPowerArrangementDesc?: string;
  meteringCharge?: number;
  meteringServiceFeePrice?: number;
}

export interface DMAContractAdditionalService {
  id?: string;
  contractId?: string;
  serviceType: DMAContractAdditionalServiceType;
  serviceFee: number;
  frequencyOnMonth: number;
  contractStartDate: string;
  contractEndDate?: string;
  nextTaskDate?: string;
  assignedEmcId: string;
  assignedEmc: User;
}

export interface IDMAContractCreateRequest {
  meterId: string;
  retailerId: string;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  ttegCommissionOnMetering?: number;
  totalFee?: number;
  baseFee?: number;
  numOfMeters?: number;
  activeDate?: string;
  agreementSignDate?: string;
  additionalServices?: DMAContractAdditionalService[];
}

export interface IBaseContract {
  id: string;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  retailSupplyCharge?: number;
  fixedRetailTariffDiscount?: number;
  retailTariffDesc?: string;
  specialConditions?: string;
  ttegCommissionOnUsage?: number;
  ttegCommissionOnMetering?: number;
  periods?: IContractPeriod[];
}

export interface INMIContract extends IBaseContract {
  contractPeakEnergy?: number;
  contractShoulderEnergy?: number;
  contractOffPeakEnergy?: number;
  solarArrangementDesc?: string;
  accreditedGreenPowerArrangementDesc?: string;
  meteringCharge?: number;
  meteringServiceFeePrice?: number;
  sourceOfDmaContract?: string;
  dmaProviderId?: string;
  dmaStartDate?: string;
  dmaEndDate?: string;
}

export interface IDMAContract extends IBaseContract {
  meterId: string;
  retailerId: string;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  ttegCommissionOnMetering?: number;
  totalFee?: number;
  baseFee?: number;
  numOfMeters?: number;
  activeDate?: string;
  agreementSignDate?: string;
  additionalServices?: DMAContractAdditionalService[];
}

export class NMIContract extends BaseEntity implements INMIContract {
  id!: string;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  retailSupplyCharge?: number;
  fixedRetailTariffDiscount?: number;
  retailTariffDesc?: string;
  specialConditions?: string;
  ttegCommissionOnUsage?: number;
  ttegCommissionOnMetering?: number;
  periods!: ContractPeriod[];
  contractPeakEnergy?: number;
  contractShoulderEnergy?: number;
  contractOffPeakEnergy?: number;
  solarArrangementDesc?: string;
  accreditedGreenPowerArrangementDesc?: string;
  meteringCharge?: number;
  meteringServiceFeePrice?: number;
  sourceOfDmaContract?: string;
  dmaProviderId?: string;
  dmaStartDate?: string;
  dmaEndDate?: string;
  retailerId?: string;
  retailer?: IRetailer;
  contractStatus?: string;

  static override from<T = NMIContract>(raw: any): T {
    return Object.assign(new NMIContract(), {
      ...raw,
      periods: raw.periods?.map((period: any) => ContractPeriod.from(period)),
      contractStatus: isPastDate(raw.contractEndDate) ? 'Not Active' : 'Active'
    });
  }

  calculateTotalEnergy(): number {
    const { contractOffPeakEnergy, contractShoulderEnergy, contractPeakEnergy } = this;
    return safeSum(contractOffPeakEnergy, contractShoulderEnergy, contractPeakEnergy);
  }

  get activeContractPeriod(): ContractPeriod | undefined {
    return this.periods?.find((period) => isDateBetween(date(), period.periodFromDate, period.periodToDate));
  }

  get dailyMeteringCharge(): number {
    return calculateDailyPriceByFeePeriod(this.meteringCharge);
  }

  get dailyMeteringServiceFee(): number {
    return calculateDailyPriceByFeePeriod(this.meteringServiceFeePrice);
  }

  get isDmaContractActive(): boolean {
    return isDateBetween(date(), this.dmaStartDate, this.dmaEndDate);
  }

  get currentDailyMeteringFee(): number {
    return this.isDmaContractActive ? this.dailyMeteringCharge : 0;
  }

  get currentDailyMeteringServiceFee(): number {
    return this.isDmaContractActive ? this.dailyMeteringServiceFee : 0;
  }
}

export class DMAContract extends BaseEntity implements IDMAContract {
  id!: string;
  meterId!: string;
  retailerId!: string;
  retailer!: IRetailer;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  ttegCommissionOnMetering?: number;
  totalFee?: number;
  baseFee?: number;
  numOfMeters?: number;
  activeDate?: string;
  agreementSignDate?: string;
  additionalServices?: DMAContractAdditionalService[];
  contractStatus?: string;

  static override from<T = DMAContract>(raw: any): T {
    return Object.assign(new DMAContract(), {
      ...raw,
      additionalServices: raw?.additionalServices?.map((service: any) => DMAContractAdditionalService.from(service)),
      contractStatus: isPastDate(raw?.contractEndDate) ? 'Not Active' : 'Active',
      ttegCommissionOnMetering:
        raw?.ttegCommissionOnMetering ??
        (raw?.totalFee ?? 0) / (raw?.numOfMeters ?? 0) - (raw?.baseFee ?? 0) * raw?.contractPeriodInYear
    });
  }

  get contractPeriodInYear(): number {
    return date(this.contractEndDate).diff(this.contractStartDate, 'day') / DAYS_PER_YEAR;
  }
}

export interface IMIRNContractCreateRequest {
  meterId: string;
  retailerId: string;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  retailSupplyCharge?: number;
  fixedRetailTariffDiscount?: number;
  retailTariffDesc?: string;
  specialConditions?: string;
  ttegCommissionOnUsage?: number;
  ttegCommissionOnMetering?: number;
  createContractPeriodRequests?: IContractPeriodCreateRequest[];
  contractAcq?: number;
  contractMdq?: number;
  contractMdqOverrunRate?: number;
  contractTakeOrPay?: number;
}

export interface IMIRNContract extends IBaseContract {
  contractAcq?: number;
  contractMdq?: number;
  contractMdqOverrunRate?: number;
  contractTakeOrPay?: number;
}

export class MIRNContract extends BaseEntity implements IMIRNContract {
  id!: string;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  retailSupplyCharge?: number;
  fixedRetailTariffDiscount?: number;
  retailTariffDesc?: string;
  specialConditions?: string;
  ttegCommissionOnUsage?: number;
  ttegCommissionOnMetering?: number;
  periods!: ContractPeriod[];
  contractAcq?: number;
  contractMdq?: number;
  contractMdqOverrunRate?: number;
  contractTakeOrPay?: number;
  retailerId?: string;
  retailer?: IRetailer;
  contractStatus?: string;

  static override from<T = MIRNContract>(raw: any): T {
    return Object.assign(new MIRNContract(), {
      ...raw,
      periods: raw.periods?.map((period: any) => ContractPeriod.from(period)),
      contractStatus: isPastDate(raw.contractEndDate) ? 'Not Active' : 'Active'
    });
  }

  get activeContractPeriod(): ContractPeriod | undefined {
    return this.periods?.find((period) => isDateBetween(date(), period.periodFromDate, period.periodToDate));
  }
}

export interface IMIRNContractUpdateRequest {
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  retailSupplyCharge?: number;
  fixedRetailTariffDiscount?: number;
  retailTariffDesc?: string;
  specialConditions?: string;
  ttegCommissionOnUsage?: number;
  ttegCommissionOnMetering?: number;
  contractAcq?: number;
  contractMdq?: number;
  contractMdqOverrunRate?: number;
  contractTakeOrPay?: number;
}

export interface IDMAContractUpdateRequest {
  retailerId: string;
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  ttegCommissionOnMetering?: number;
  totalFee?: number;
  baseFee?: number;
  numOfMeters?: number;
  activeDate?: string;
  agreementSignDate?: string;
  additionalServices?: DMAContractAdditionalService[];
}

export interface INMIContractUpdateRequest {
  retailContractRefNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  retailSupplyCharge?: number;
  fixedRetailTariffDiscount?: number;
  retailTariffDesc?: string;
  specialConditions?: string;
  ttegCommissionOnUsage?: number;
  ttegCommissionOnMetering?: number;
  contractPeakEnergy?: number;
  contractShoulderEnergy?: number;
  contractOffPeakEnergy?: number;
  solarArrangementDesc?: string;
  accreditedGreenPowerArrangementDesc?: string;
  meteringCharge?: number;
  meteringServiceFeePrice?: number;
  sourceOfDmaContract?: string;
  dmaProviderId?: string;
  dmaStartDate?: string;
  dmaEndDate?: string;
}

export interface MeterSearchCriteria {
  retailerName?: string;
  meterNumber?: number;
  states?: string[];
  contactName?: string;
  contractEndDateTo?: string | null;
  contractEndDateFrom?: string | null;
  contractTypes?: string[];
  usageFloor?: number;
  usageUpper?: number;
  accountManagerIds?: string[];
  sourceOfDmaContract?: string;
  dmaProviderIds?: string[];
  dmaContractEndDateFrom?: string | null;
  dmaContractEndDateTo?: string | null;
  hasDmaContract?: number | boolean | null;
  companyIds?: string[];
}

export type MeterSortType = 'UPDATED_DATE_ASC' | 'UPDATED_DATE_DESC' | 'CREATED_DATE_ASC' | 'CREATED_DATE_DESC';

export interface MeterSortConfig {
  sortBy?: MeterSortType;
}

export interface IElectricityContractOutlineResponse {
  id: string;
  nmiId?: string;
  nmiNumber?: string;
  retailer?: IRetailerOutlineResponse;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  bundleDemandCharge?: number;
  fixedRetailTariffDiscount?: number;
  retailTariffDesc?: string;
  specialConditions?: string;
  usage?: number;
  dmaStartDate?: string;
  dmaEndDate?: string;
  contractPeakEnergy?: number;
  contractShoulderEnergy?: number;
  contractOffPeakEnergy?: number;
}

export interface INmiDetailPageResponse {
  id: string;
  energyType: EnergyType;
  meterNumber: string;
  meterType: NMIMeterType;
  refType?: string;
  refId?: string;
  streetAddress?: string;
  suburb?: string;
  state?: string;
  postCode?: string;
  sourceOfEstConsumption?: string;
  invoiceBillingPeriodFromDate?: string;
  invoiceBillingPeriodToDate?: string;
  invoiceIssueDate?: string;
  networkDistributor?: string;
  networkTariffCode?: string;
  networkTariffDesc?: string;
  siteClassification?: string;
  description?: string;
  checkSum?: number;
  contracts?: IElectricityContractOutlineResponse[];
  dmaContracts?: DMAContract[];
  numOfMeters: number;
  dlf?: number;
  mlf?: number;
  tlf?: number;
  installedSolarCapacity?: number;
  annualEstPeakEnergy?: number;
  annualEstShoulderEnergy?: number;
  annualEstOffPeakEnergy?: number;
  loadFactor?: number;
  powerFactor?: number;
  maxDemand?: number;
  feedInTariff?: string;
  company?: ICompanyOutlineResponse;
  council?: ICouncilOutlineResponse;
  createAt?: string;
  updateAt?: string;
  deleteAt?: string;
  participantCharge?: number | null;
  ancillaryCharge?: number | null;
  networkChargesStanding?: NetworkChargeStanding[];
  networkChargesEnergy?: NetworkChargeEnergy[];
  networkChargesDemand?: NetworkChargeDemand[];
}

export interface IGasContractOutlineResponse {
  id: string;
  mirnId?: string;
  mirnNumber?: string;
  retailer?: IRetailerOutlineResponse;
  contractStartDate?: string;
  contractEndDate?: string;
  sourceOfContract?: string;
  contractType?: string;
  contractCategory?: string;
  retailTariffDesc?: string;
  specialConditions?: string;
  usage?: number;
}

export interface IMirnDetailPageResponse {
  id: string;
  energyType: string;
  meterNumber: string;
  meterType: MIRNMeterType;
  streetAddress?: string;
  suburb?: string;
  state?: string;
  postCode?: string;
  sourceOfEstConsumption?: string;
  invoiceBillingPeriodFromDate?: string;
  invoiceBillingPeriodToDate?: string;
  invoiceIssueDate?: string;
  networkDistributor?: string;
  networkTariffCode?: string;
  networkTariffDesc?: string;
  siteClassification?: string;
  description?: string;
  contracts?: IGasContractOutlineResponse[];
  uafg?: number;
  annualContractQuantity?: number;
  mdq?: number;
  mhq?: number;
  networkMaximumDailyQuantity?: number;
  loadFactor?: number;
  distributionZone?: string;
  company?: ICompanyOutlineResponse;
  council?: ICouncilOutlineResponse;
  createAt?: string;
  updateAt?: string;
  deleteAt?: string;
}

export class ContractPeriod extends BaseEntity implements IContractPeriod {
  id!: string;
  periodFromDate!: string;
  periodToDate!: string;
  peakRate?: number;
  shoulderRate?: number;
  offPeakRate?: number;
  lretRate?: number;
  srecRate?: number;
  veetRate?: number;
  essRate?: number;
  aeeisRate?: number;
  commodityRate?: number;
  fixedTuosCharge?: number;

  static override from<T = ContractPeriod>(raw: any): T {
    return Object.assign(new ContractPeriod(), {
      ...raw,
      peakRate: raw.peakRate ?? 0,
      shoulderRate: raw.shoulderRate ?? 0,
      offPeakRate: raw.offPeakRate ?? 0
    });
  }
}

export class DMAContractAdditionalService extends BaseEntity {
  serviceType!: DMAContractAdditionalServiceType;
  serviceFee!: number;
  frequencyOnMonth!: number;
  contractStartDate!: string;
  contractEndDate?: string;
  nextTaskDate?: string;
  assignedEmcId!: string;
  assignedEmc!: User;
}
