import { BaseEntity } from '~/lib/model/base';

export enum TenderRegion {
  WA = 'WA',
  NEM = 'NEM'
}

export enum ServiceType {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
  DMA = 'DMA'
}

export enum ServiceCategory {
  SMALL = 'SMALL',
  LARGE = 'LARGE'
}

export enum FeePeriod {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL'
}

export enum GreenBillingCalculation {
  DLF_MLF = 'DLF_MLF',
  DLF = 'DLF',
  MLF = 'MLF'
}

export interface IRetailerContact {
  id: string;
  name: string;
  position: string;
  phone: string;
  mobile: string;
  fax: string;
  email: string;
  precedence: number;
  salutation: string;
  description: string;
}

export interface IRetailerContract {
  id: string;
  serviceType: ServiceType;
  serviceCategory?: ServiceCategory;
  // dd/MM/yyyy
  contractStartDate: string;
  // dd/MM/yyyy
  contractEndDate: string;
  description: string;
}

export interface IRetailerElectricityContract {
  externalRef: string;
  nmiId: string;
  nmiNumber: string;
  retailer: any;
  contractStartDate: string;
  contractEndDate: string;
  sourceOfContract: string;
  contractStatus: string;
  contractType: string;
  contractCategory: string;
  retailTariffDesc: string;
  specialConditions: string;
  usage: 0;
  dmaStartDate: string;
  dmaEndDate: string;
}
export interface IRetailerGasContract {
  externalRef: string;
  mirnId: string;
  mirnNumber: string;
  retailer: any;
  contractStartDate: string;
  contractEndDate: string;
  sourceOfContract: string;
  contractStatus: string;
  contractType: string;
  contractCategory: string;
  retailTariffDesc: string;
  specialConditions: string;
  usage: 0;
}

export interface IRetailerBasic {
  id: string;
  name: string;
  tenderRegion: TenderRegion[];
  meteringFeePeriod?: FeePeriod;
  meteringFeePrice?: number;
  suppDMAOrRPFeePeriod?: FeePeriod;
  suppDMAOrRPFeePrice?: number;
  serviceFeePeriod?: FeePeriod;
  serviceFeePrice?: number;
  greenBilling?: GreenBillingCalculation;
}

export interface IRetailer extends IRetailerBasic {
  contacts: IRetailerContact[];
  contracts: IRetailerContract[];
}

export interface IRetailerCreateRequest {
  name: string;
  tenderRegion: TenderRegion[];
  contacts?: Pick<IRetailerContact, 'name' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'precedence'>[];
  contracts?: Pick<IRetailerContract, 'serviceType' | 'serviceCategory' | 'contractStartDate' | 'contractEndDate'>[];
}

export type IRetailerUpdateRequest = IRetailerCreateRequest;

export class Retailer extends BaseEntity implements IRetailer {
  id!: string;
  name!: string;
  tenderRegion!: TenderRegion[];
  meteringFeePeriod?: FeePeriod;
  meteringFeePrice?: number;
  suppDMAOrRPFeePeriod?: FeePeriod;
  suppDMAOrRPFeePrice?: number;
  serviceFeePeriod?: FeePeriod;
  serviceFeePrice?: number;
  greenBilling?: GreenBillingCalculation;
  contacts!: IRetailerContact[];
  contracts!: IRetailerContract[];
  electricityContracts?: IRetailerElectricityContract[];
  gasContracts?: IRetailerGasContract[];
}

export interface IRetailerOutlineResponse {
  id: string;
  name: string;
  // TODO: change to array after backend refactored.
  tenderRegion?: string;
}
