import { first, last, sum, sumBy } from 'lodash-es';
import { ILookupPrimaryResponse } from '~/features/lookup/models/lookup';
import {
  EnergyPricingOfferTypes,
  GreenPricingType,
  GreenPricingTypes,
  ProjectOffer,
  ProjectOfferPeriod
} from '~/models/project';
import { CalculatedProjectOfferPeriod } from '~/pages/dashboard/project/EAReport/types';

export const calculateGreenPrice = (
  greenPriceType: GreenPricingType,
  period: ProjectOfferPeriod,
  offer: ProjectOffer,
  startYearGreenPrice?: ILookupPrimaryResponse,
  endYearGreenPrice?: ILookupPrimaryResponse
): number => {
  let greenPrice = 0;
  if (
    period[`${greenPriceType}Type`] === GreenPricingTypes.CAL_CERT ||
    period[`${greenPriceType}Type`] === GreenPricingTypes.MAT_CERT
  ) {
    greenPrice =
      (startYearGreenPrice?.[greenPriceType] ?? 0) *
        period.daysStart *
        (period[`${greenPriceType}Type`] === GreenPricingTypes.CAL_CERT ? period[greenPriceType] ?? 0 : 1) +
      (endYearGreenPrice?.[greenPriceType] ?? 0) *
        period.daysEnd *
        (period[`${greenPriceType}Type`] === GreenPricingTypes.CAL_CERT ? period[greenPriceType] ?? 0 : 1);
    return (
      (greenPrice / sum([period.daysStart, period.daysEnd]) / 1000) *
      (period[`${greenPriceType}Type`] === GreenPricingTypes.CAL_CERT ||
      period[`${greenPriceType}Type`] === GreenPricingTypes.CAL_RATE
        ? 1
        : period?.[greenPriceType] ?? 0)
    );
  }
  return period?.[greenPriceType] ?? 0;
};

export const getWeightedGreenPrice = (greenPricingType: GreenPricingType, periods: CalculatedProjectOfferPeriod[]) => {
  return first(periods)?.offerType === EnergyPricingOfferTypes.FLAT
    ? sumBy(periods, (v) => v.numOfDays * (v[greenPricingType] ?? 0)) / sumBy(periods, (v) => v.numOfDays)
    : last(periods)?.[greenPricingType];
};
