export enum AttachmentRefType {
  PROJECT = 'PROJECT',
  RETAILER = 'RETAILER',
  COMPANY = 'COMPANY'
}

export enum AttachmentType {
  GENERAL = 'GENERAL',
  BILL = 'BILL',
  IA_FORM = 'IA_FORM',
  CONTRACTS = 'CONTRACTS',
  BILLS_AND_DATA = 'BILLS_AND_DATA',
  CUSTOMER_ENGAGEMENT = 'CUSTOMER_ENGAGEMENT',
  OTHERS = 'OTHERS'
}

export interface IAttachmentRequestBody {
  refType: AttachmentRefType;
  refId: string;
  file: File;
}

export interface IUploadAttachmentResponse {
  name: string;
  location: string;
  size: number;
  contentType: string;
  signedUrl: string;
}

export interface IAttachmentForAllResponse {
  id: string;
  refType: AttachmentRefType;
  refId: string;
  type: AttachmentType;
  name: string;
  location: string;
  size: number;
  contentType: string;
  signedUrl: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
}

export interface ICreateAttachmentRequest {
  type: AttachmentType;
  name: string;
  location: string;
  size: number;
  contentType: string;
  description?: string;
}
