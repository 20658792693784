import { styled } from '@mui/material';
import { Outlet } from 'react-router';

const PreviewLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%'
}));

const PreviewLayout: React.FC = () => {
  return (
    <PreviewLayoutRoot>
      <Outlet />
    </PreviewLayoutRoot>
  );
};

export default PreviewLayout;
