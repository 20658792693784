import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { omit } from 'lodash-es';
import { auditTime, BehaviorSubject, merge } from 'rxjs';
import { COGNITO, SERVER_URL } from '~/config';
import { CompleteNewPasswordFailedError } from '~/errors/CompleteNewPasswordFailedError';
import { ConfirmPasswordFailedError } from '~/errors/ConfirmPasswordFailedError';
import { ForgotPasswordFailedError } from '~/errors/ForgotPasswordFailedError';
import { LoginFailedError } from '~/errors/LoginFailedError';
import { NeedCompleteNewPasswordError } from '~/errors/NeedCompleteNewPasswordError';
import { NoLoggedInUserError } from '~/errors/NoLoggedInUserError';
import { IUser, Permission, User } from '~/models/user';

export class Session {
  private updateHandlers = new Set<() => void>();
  private userPool = new CognitoUserPool({
    UserPoolId: COGNITO.USER_POOL_ID,
    ClientId: COGNITO.APP_CLIENT_ID
  });
  private initializing$ = new BehaviorSubject(false);
  private completeNewPasswordContext$ = new BehaviorSubject<{ user: CognitoUser; userAttributes: any } | null>(null);
  private user$ = new BehaviorSubject<User | null>(null);

  get initializing(): boolean {
    return this.initializing$.value;
  }

  get completeNewPasswordContext(): { user: CognitoUser; userAttributes: any } | null {
    return this.completeNewPasswordContext$.value;
  }

  get user(): User | null {
    return this.user$.value;
  }

  constructor() {
    merge(this.initializing$, this.completeNewPasswordContext$, this.user$)
      .pipe(auditTime(100))
      .subscribe(() => this.updateHandlers.forEach((update) => update()));
  }

  private getOrRefreshCognitoSession(): Promise<CognitoUserSession> {
    const currentUser = this.userPool.getCurrentUser();
    if (!currentUser) {
      throw new NoLoggedInUserError();
    }
    return new Promise((resolve, reject) => {
      currentUser.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error) {
          return reject(error);
        }
        resolve(session);
      });
    });
  }

  async init(): Promise<void> {
    if (this.initializing) {
      return;
    }
    this.initializing$.next(true);
    try {
      await this.updateUserInfo();
    } finally {
      this.initializing$.next(false);
    }
  }

  async login(email: string, password: string): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      const user = new CognitoUser({ Username: email, Pool: this.userPool });
      const authDetails = new AuthenticationDetails({ Username: email, Password: password });

      user.authenticateUser(authDetails, {
        onSuccess: () => resolve(),
        onFailure: (error: Error) => reject(new LoginFailedError(error.message)),
        newPasswordRequired: (userAttributes) => {
          this.completeNewPasswordContext$.next({ user, userAttributes });
          reject(new NeedCompleteNewPasswordError());
        }
      });
    });
  }

  async logout(): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      const currentUser = this.userPool.getCurrentUser();
      if (currentUser) {
        currentUser.signOut(resolve);
      } else {
        reject(new NoLoggedInUserError());
      }
    });
    this.user$.next(null);
  }

  async completeNewPassword(newPassword: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.completeNewPasswordContext) {
        return reject(new CompleteNewPasswordFailedError('No complete new password context'));
      }
      const { user, userAttributes } = this.completeNewPasswordContext;
      user.completeNewPasswordChallenge(
        newPassword,
        // TODO: AWS bug? see https://stackoverflow.com/questions/71667989/aws-cognito-respond-to-new-password-required-challenge-returns-cannot-modify-an
        omit(userAttributes, 'email'),
        {
          onSuccess: () => {
            this.completeNewPasswordContext$.next(null);
            resolve();
          },
          onFailure: (error) => reject(new CompleteNewPasswordFailedError(error.message))
        }
      );
    });
  }

  async forgotPassword(email: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const user = new CognitoUser({ Username: email, Pool: this.userPool });
      user.forgotPassword({
        onSuccess: () => resolve(),
        onFailure: (error) => reject(new ForgotPasswordFailedError(error.message))
      });
    });
  }

  async confirmPassword(email: string, code: string, password: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const user = new CognitoUser({ Username: email, Pool: this.userPool });
      user.confirmPassword(code, password, {
        onSuccess: () => resolve(),
        onFailure: (error) => reject(new ConfirmPasswordFailedError(error.message))
      });
    });
  }

  async getAccessToken(): Promise<string> {
    const cognitoSession = await this.getOrRefreshCognitoSession();
    return cognitoSession.getAccessToken().getJwtToken();
  }

  async updateUserInfo(): Promise<void> {
    const accessToken = await this.getAccessToken();
    const { data } = await axios.get<IUser>(`${SERVER_URL}/users/my/profile`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    this.user$.next(User.from(data));
  }

  hasPermission(requiredPermissions?: Permission[]): boolean {
    const { user } = this;

    if (!requiredPermissions) {
      return true;
    }

    if (!user?.permissions) {
      return false;
    }

    const userPermissions = new Set(user.permissions);

    for (const requiredPermission of requiredPermissions) {
      if (!userPermissions.has(requiredPermission)) {
        return false;
      }
    }

    return true;
  }

  onUpdate(update: () => void): void {
    this.updateHandlers.add(update);
  }

  removeOnUpdateHandler(update: () => void): void {
    this.updateHandlers.delete(update);
  }
}
