import { DAYS_PER_YEAR, MONTHS_PER_YEAR } from '~/constants/date';
import { Association } from './association';
import { Company } from './company';
import { Council } from './council';
import { MIRN, NMI } from './meter';
import { Project } from './project';
import { FeePeriod, Retailer } from './retailer';

export enum State {
  NSW = 'New South Wales',
  QLD = 'Queensland',
  SA = 'South Australia',
  TAS = 'Tasmania',
  VIC = 'Victoria',
  WA = 'Western Australia'
}

export enum RefType {
  COMPANY = 'COMPANY',
  PROJECT = 'PROJECT',
  COUNCIL = 'COUNCIL',
  RETAILER = 'RETAILER',
  ASSOCIATION = 'ASSOCIATION',
  NMI = 'NMI',
  MIRN = 'MIRN'
}

export type RefObj = Company | Council | Retailer | Association | Project | NMI | MIRN;

export const calculateDailyPriceByFeePeriod = (price = 0, priceType: FeePeriod = FeePeriod.ANNUAL): number => {
  return priceType === FeePeriod.MONTHLY
    ? (price * MONTHS_PER_YEAR) / DAYS_PER_YEAR
    : priceType === FeePeriod.ANNUAL
    ? price / DAYS_PER_YEAR
    : price;
};

export const calculateDailyEnergy = (energy = 0) => {
  return energy / DAYS_PER_YEAR;
};

export interface IAction {
  edit: boolean;
  view: boolean;
  delete: boolean;
}
