import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { map } from 'lodash-es';
import { QueryOptions } from '~/api/types';
import { SERVER_URL } from '~/config';
import { IShareLink, IShareLinkCreateRequest, ShareLink, ShareLinkRefType } from '~/models/share';
import { useHttpClient } from './base';

const BASE_URL = '/share-links';

export function useAllShareLinks(
  refType: ShareLinkRefType,
  refId: string
): UseQueryResult<ShareLink[] | null, unknown> {
  const client = useHttpClient(BASE_URL);
  return useQuery(['shareLinks', 'all'], async () => {
    const { data } = await client.get<IShareLink[]>(`all?refType=${refType}&refId=${refId}`);
    return map(data, (it) => ShareLink.from(it));
  });
}

export function useCreateShareLink(): UseMutationResult<IShareLink, unknown, IShareLinkCreateRequest, unknown> {
  const client = useHttpClient(BASE_URL);
  const queryClient = useQueryClient();
  return useMutation(
    async (shareLinkCreateRequest) => {
      const { data } = await client.post<IShareLink>('', shareLinkCreateRequest);
      return data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['shareLinks']);
      }
    }
  );
}

export function useDeleteShareLink(): UseMutationResult<void, unknown, string, unknown> {
  const client = useHttpClient(BASE_URL);
  const queryClient = useQueryClient();
  return useMutation(
    async (id) => {
      await client.delete(id);
    },
    {
      onSuccess: async (_, id) => {
        await Promise.all([
          queryClient.invalidateQueries(['shareLink', id]),
          queryClient.invalidateQueries(['shareLinks'])
        ]);
      }
    }
  );
}

export function useShareLink(sid: string, pwd: string, options?: QueryOptions): UseQueryResult<ShareLink, unknown> {
  const client = axios.create({
    baseURL: SERVER_URL + '/share'
  });
  return useQuery(
    ['shareLink', sid],
    async () => {
      const { data } = await client.get<IShareLink>(sid, {
        headers: { 'X-Password': pwd }
      });
      return ShareLink.from(data);
    },
    options
  );
}
