import { createPath, END } from 'typage-url';

export const $ = createPath({
  [END]: true,
  accessDenied: {},
  notFound: {},
  login: {},
  completeNewPassword: {},
  forgotPassword: {},
  preview: {
    [END]: true,
    report: { [END]: true, ':id': {} }
  },
  dashboard: {
    [END]: true,
    account: {},
    task: {},
    notification: {},
    user: { [END]: true, create: {} },
    nmi: { [END]: true, create: {}, ':id': {}, detail: { ':id': {} } },
    mirn: { [END]: true, create: {}, ':id': {}, detail: { ':id': {} } },
    retailer: { [END]: true, create: {}, ':id': {}, detail: { ':id': {} } },
    company: { [END]: true, create: {}, ':id': {}, detail: { ':id': {} } },
    council: { [END]: true, create: {}, ':id': {}, detail: { ':id': {} } },
    association: { [END]: true, create: {}, ':id': {}, detail: { ':id': {} } },
    project: { [END]: true, create: {}, ':id': {}, detail: { ':id': {} } },
    lookup: { [END]: true, create: {} }
  }
});
