import { useUpdate } from 'ahooks';
import { useEffect } from 'react';
import { Session } from '.';

const session = new Session();

export const useSession = (): Session => {
  const update = useUpdate();

  useEffect(() => {
    session.onUpdate(update);
    return () => session.removeOnUpdateHandler(update);
  }, [update]);

  return session;
};
