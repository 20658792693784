import { BaseEntity } from '~/lib/model/base';

export enum ShareLinkRefType {
  PROJECT = 'PROJECT'
}

export interface IShareLinkCreateRequest {
  refType: ShareLinkRefType;
  refId: string;
  metadata: object;
  expiration?: string;
  description?: string;
}

export interface IShareLink {
  id: string;
  sid: string;
  link: string;
  refType?: ShareLinkRefType;
  refId?: string;
  endpoint: string;
  password: string;
  expiration: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export class ShareLink extends BaseEntity implements IShareLink {
  id!: string;
  sid!: string;
  link!: string;
  refType?: ShareLinkRefType;
  refId?: string;
  metadata?: object;
  endpoint!: string;
  password!: string;
  expiration!: string;
  description!: string;
  createdAt!: string;
  updatedAt!: string;
}
