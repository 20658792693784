export interface BaseErrorProps {
  code: string;
  message: string;
}

export abstract class BaseError extends Error {
  public code: string;

  constructor({ code, message }: BaseErrorProps) {
    super(message);

    this.code = code;
  }
}
