import { padStart, pick, sortBy, uniq, uniqBy } from 'lodash-es';
import { AttachmentType } from '~/features/attachment/models/attachment';
import { date } from '~/lib/date';
import { addReadableInfo } from '~/lib/enum/readable';
import { safeSum } from '~/lib/jsTools/number';
import { BaseEntity } from '~/lib/model/base';
import { calculateDailyPriceByFeePeriod, IAction } from '~/models/common';
import { Company } from '~/models/company';
import { EnergyType, IMirnDetailPageResponse, INmiDetailPageResponse, NMI } from '~/models/meter';
import { IUser, User } from '~/models/user';
import { MeterGroup } from '~/pages/dashboard/project/EAReport/types';
import { FeePeriod, GreenBillingCalculation, Retailer } from './retailer';

export enum ProjectType {
  NEW = 'NEW',
  RENEW = 'RENEW'
}

export enum ProjectPriorityType {
  EXTREME = 'EXTREME',
  URGENT = 'URGENT',
  NORMAL = 'NORMAL'
}

export const ProjectAttachmentType = pick(AttachmentType, [
  'BILL',
  'BILLS_AND_DATA',
  'CONTRACTS',
  'CUSTOMER_ENGAGEMENT',
  'IA_FORM',
  'OTHERS'
]);

addReadableInfo(ProjectAttachmentType, {
  BILL: 'Bill',
  BILLS_AND_DATA: 'Biils and Data',
  CONTRACTS: 'Retailer Contract, LOA, Engagement Form',
  CUSTOMER_ENGAGEMENT: 'Customer Engagement',
  IA_FORM: 'IA Form',
  OTHERS: 'Others'
});

export type ProjectAttachmentType =
  | AttachmentType.BILL
  | AttachmentType.IA_FORM
  | AttachmentType.CUSTOMER_ENGAGEMENT
  | AttachmentType.BILLS_AND_DATA
  | AttachmentType.CONTRACTS
  | AttachmentType.OTHERS;

export enum ProjectStatus {
  PROSPECT = 'PROSPECT',
  IA_REQUESTED = 'IA_REQUESTED',
  IA_ASSIGNED = 'IA_ASSIGNED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  ON_HOLD = 'ON_HOLD',
  IA_FINISHED = 'IA_FINISHED',
  OPPORTUNITY_CANCELLED = 'OPPORTUNITY_CANCELLED',
  ENGAGED = 'ENGAGED',
  PROJECT_PREPARATION = 'PROJECT_PREPARATION',
  PROJECT_APPROVED = 'PROJECT_APPROVED',
  ON_HOLD_REQUESTED = 'ON_HOLD_REQUESTED',
  TENDER_PREPARING = 'TENDER_PREPARING',
  TENDER_REQUESTED = 'TENDER_REQUESTED',
  TENDER_ASSIGNED = 'TENDER_ASSIGNED',
  TENDERED = 'TENDERED',
  TENDER_CLOSED = 'TENDER_CLOSED',
  EA_PREPARING = 'EA_PREPARING',
  EA_COMPLETED = 'EA_COMPLETED',
  EA_REPORT_SENT = 'EA_REPORT_SENT',
  REPRICED = 'REPRICED',
  OFFER_REJECT_REQUESTED = 'OFFER_REJECT_REQUESTED',
  OFFER_REJECTED = 'OFFER_REJECTED',
  OFFER_SIGNED = 'OFFER_SIGNED',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  BACK_TO_SALES_AFTER_REJECT_PROJECT_REQUESTED = 'BACK_TO_SALES_AFTER_REJECT_PROJECT_REQUESTED',
  NOT_ACCEPT_OFFER_REQUESTED = 'NOT_ACCEPT_OFFER_REQUESTED'
}

export enum ProcessProjectAction {
  REQUEST_IA = 'REQUEST_IA',
  ASSIGNED_IA = 'ASSIGNED_IA',
  FINISH_IA = 'FINISH_IA',
  DISCONTINUE = 'DISCONTINUE',
  REGISTER_ENGAGEMENT = 'REGISTER_ENGAGEMENT',
  PREPARE_PROJECT = 'PREPARE_PROJECT',
  APPROVE_PROJECT = 'APPROVE_PROJECT',
  REQUEST_MORE_DOCS = 'REQUEST_MORE_DOCS',
  BACK_TO_PROCESS = 'BACK_TO_PROCESS',
  PROCEED_TENDER = 'PROCEED_TENDER',
  TENDERED = 'TENDERED',
  CLOSE_TENDER = 'CLOSE_TENDER',
  REQUEST_TENDER = 'REQUEST_TENDER',
  ASSIGNED_TENDER = 'ASSIGNED_TENDER',
  REQUEST_ON_HOLD = 'REQUEST_ON_HOLD',
  APPROVE_ON_HOLD = 'APPROVE_ON_HOLD',
  PREPARE_EA = 'PREPARE_EA',
  COMPLETE_EA = 'COMPLETE_EA',
  SENT_EA_REPORT = 'SENT_EA_REPORT',
  REQUEST_REJECT = 'REQUEST_REJECT',
  APPROVE_REJECT = 'APPROVE_REJECT',
  BACK_TO_SALES_AFTER_REJECT_PROJECT = 'BACK_TO_SALES_AFTER_REJECT_PROJECT',
  RE_TENDER = 'RE_TENDER',
  REPRICE = 'REPRICE',
  SIGN_OFFER = 'SIGN_OFFER',
  ACCEPT_OFFER = 'ACCEPT_OFFER',
  CLOSE_PROJECT = 'CLOSE_PROJECT',
  NOT_ACCEPT_OFFER = 'NOT_ACCEPT_OFFER',
  CANCEL_PROJECT = 'CANCEL_PROJECT'
}

export enum PaymentType {
  COMMISSION = 'COMMISSION',
  ONCE_OFF = 'ONCE_OFF'
}

export interface IProcessProjectParameter {
  assignedIAEmcId?: string;
  assignedTenderEmcId?: string;
  dueDateTime?: string;
}

export interface IProjectDetailResponse {
  id: string;
  meterIds: string[];
  number: string;
  status: ProjectStatus;
  type?: ProjectType;
  assignedIAEmc?: IUser;
  assignedTenderEmc?: IUser;
  assignedSales?: IUser;
  assignedIAEmcId?: string;
  assignedTenderEmcId?: string;
  createdSalesId?: string;
  assignedSalesId?: string;
  priority?: ProjectPriorityType;
  nmis: INmiDetailPageResponse[];
  mirns: IMirnDetailPageResponse[];
  offers?: IProjectOfferPrimaryResponse[];
  onHoldTillTime?: string;
  createdAt: string;
  createdBy: IUser;
  updatedAt?: string;
  updatedBy?: IUser;
  paymentType?: PaymentType;
  commissionRate?: number;
  estAnnualCommission?: number;
}

export interface IProjectOfferPrimaryResponse {
  id?: string;
  retailerId: string;
  meterIds: string[];
  retailer: Retailer;
  meteringFeePeriod?: FeePeriod;
  meteringFeePrice?: number;
  suppDMAOrRPFeePeriod?: FeePeriod;
  suppDMAOrRPFeePrice?: number;
  serviceFeePeriod?: FeePeriod;
  serviceFeePrice?: number;
  greenBilling?: GreenBillingCalculation;
  discount?: number;
  validityEndDateTime?: string;
  periods?: IUpsertProjectOfferPeriodRequest[];
  description?: string;
}

export interface ICreateProjectRequest {
  meterIds: string[];
  type?: ProjectType;
  priority?: ProjectPriorityType;
}

export const formatProjectIdentifier = (projectNumber: string): string => {
  const [number, ...date] = projectNumber.split('-');
  return date.join('') + padStart(number, 4, '0');
};

export class Project extends BaseEntity implements IProjectDetailResponse {
  id!: string;
  meterIds!: string[];
  number!: string;
  status!: ProjectStatus;
  type?: ProjectType;
  assignedIAEmcId?: string;
  assignedTenderEmcId?: string;
  createdSalesId?: string;
  assignedSalesId?: string;
  assignedIAEmc?: IUser;
  assignedTenderEmc?: IUser;
  assignedSales?: IUser;
  priority?: ProjectPriorityType;
  nmis!: INmiDetailPageResponse[];
  mirns!: IMirnDetailPageResponse[];
  offers?: IProjectOfferPrimaryResponse[];
  startDate?: string;
  endDate?: string;
  onHoldTillTime?: string;
  createdAt!: string;
  createdBy!: IUser;
  updatedAt?: string;
  updatedBy?: IUser;
  paymentType?: PaymentType;
  commissionRate?: number;
  estAnnualCommission?: number;

  static override from<T = Project>(raw: any): T {
    return Object.assign(new Project(), {
      ...raw,
      offers: (raw?.offers ?? []).map((offer: any) => ProjectOffer.from(offer))
    });
  }

  get meters(): (INmiDetailPageResponse | IMirnDetailPageResponse)[] {
    return [
      ...(this.nmis || []).map((v) => ({ ...v, energyType: EnergyType.ELECTRICITY } as INmiDetailPageResponse)),
      ...(this.mirns || []).map((v) => ({ ...v, energyType: EnergyType.GAS } as IMirnDetailPageResponse))
    ];
  }

  get companies(): Company[] {
    const { nmis = [], mirns = [] } = this;
    return uniqBy([...nmis, ...mirns].map(({ company }) => company).filter((v) => !!v) as Company[], 'id');
  }

  get companyIds(): string[] {
    const { nmis = [], mirns = [] } = this;
    return uniq([...nmis, ...mirns].map(({ company }) => company?.id).filter((v) => !!v) as string[]);
  }

  get projectIdentifier(): string {
    return formatProjectIdentifier(this.number);
  }

  get isOngoingProject(): boolean {
    return ![
      ProjectStatus.CLOSED,
      ProjectStatus.CANCELLED,
      ProjectStatus.OPPORTUNITY_CANCELLED,
      ProjectStatus.OFFER_REJECTED
    ].includes(this.status);
  }

  getMeterById(meterId: string): INmiDetailPageResponse | IMirnDetailPageResponse | undefined {
    const nmi = this.nmis.find((it) => it.id === meterId);
    if (nmi) {
      return nmi;
    }
    const mirn = this.mirns.find((it) => it.id === meterId);
    if (mirn) {
      return mirn;
    }
  }

  getMetersById(meterIds: string[]): (INmiDetailPageResponse | IMirnDetailPageResponse)[] {
    return meterIds
      .map((it) => this.getMeterById(it))
      .filter((it): it is INmiDetailPageResponse | IMirnDetailPageResponse => !!it);
  }

  // kWh .p.a
  static calculateAnnualUsage(nmis: NMI[]): number {
    const calculateNmiUsage = (nmi: NMI) => {
      const latestContract = nmi.contracts?.[0];
      return latestContract
        ? safeSum(
            latestContract.contractOffPeakEnergy,
            latestContract.contractPeakEnergy,
            latestContract.contractShoulderEnergy
          )
        : safeSum(nmi.annualEstOffPeakEnergy, nmi.annualEstPeakEnergy, nmi.annualEstShoulderEnergy);
    };

    return nmis.reduce((usage, nmi) => usage + calculateNmiUsage(nmi), 0);
  }
}

export interface IProjectQueryResponse {
  id: string;
  meterIds: string[];
  number: string;
  type?: ProjectType;
  priority?: ProjectPriorityType;
  status: string;
  createdAt: string;
  updatedAt: string;
  statusDueDateTime?: string;
  onHoldTillTime?: string;
  createdBy: IUser;
  assignedIAEmc?: IUser;
  assignedTenderEmc?: IUser;
  assignedSales?: IUser;
  nmis: INmiDetailPageResponse[];
  mirns: IMirnDetailPageResponse[];
  action: IAction;
  estAnnualCommission?: number;
}

export type ITemporaryProject = Pick<Project, 'type' | 'priority' | 'meterIds' | 'companyIds'>;

export interface IUpdateProjectRequest {
  meterIds?: string[];
  priority?: ProjectPriorityType;
  type?: ProjectType;
}

export interface IProcessProjectRequest {
  action: ProcessProjectAction;
  processProjectParameter?: IProcessProjectParameter;
}

export interface ProjectSearchCriteria {
  number?: string;
  companyName?: string;
  companyAbn?: string;
  companyAcn?: string;
  meterNumber?: string;
  type?: ProjectType;
  priority?: ProjectPriorityType;
  status?: ProjectStatus;
  assignedIAEmcId?: string;
  assignedTenderEmcId?: string;
  assignedSalesId?: string;
  createdBy?: string;
}

export enum EnergyPricingOfferTypes {
  FLAT = 'FLAT',
  STEPPED = 'STEPPED'
}

export enum GreenPricingTypes {
  CAL_CERT = 'CAL_CERT',
  CAL_RATE = 'CAL_RATE',
  MAT_CERT = 'MAT_CERT',
  MAT_RATE = 'MAT_RATE'
}

export type GreenPricingType = 'lret' | 'sres' | 'ess' | 'veet' | 'aeeis';

export interface IUpsertProjectOfferPeriodRequest {
  id?: string;
  periodName: number;
  offerType?: EnergyPricingOfferTypes;
  startDate: string;
  endDate: string;
  peak?: number;
  shoulder?: number;
  offPeak?: number;
  lretType?: GreenPricingTypes;
  lret?: number;
  sresType?: GreenPricingTypes;
  sres?: number;
  essType?: GreenPricingTypes;
  ess?: number;
  veetType?: GreenPricingTypes;
  veet?: number;
  aeeisType?: GreenPricingTypes;
  aeeis?: number;
  description?: string;
}

export interface IUpsertProjectOfferRequest {
  id?: string;
  retailerId: string;
  meteringFeePeriod?: FeePeriod;
  meteringFeePrice?: number;
  suppDMAOrRPFeePeriod?: FeePeriod;
  suppDMAOrRPFeePrice?: number;
  serviceFeePeriod?: FeePeriod;
  serviceFeePrice?: number;
  greenBilling?: GreenBillingCalculation;
  discount?: number;
  validityEndDateTime?: string;
  periods?: IUpsertProjectOfferPeriodRequest[];
  description?: string;
}

export interface ICreateProjectOfferRequest {
  retailerId: string;
  meterIds?: string[];
  meteringFeePeriod?: FeePeriod;
  meteringFeePrice?: number;
  suppDMAOrRPFeePeriod?: FeePeriod;
  suppDMAOrRPFeePrice?: number;
  serviceFeePeriod?: FeePeriod;
  serviceFeePrice?: number;
  greenBilling?: GreenBillingCalculation;
  discount?: number;
  validityEndDateTime?: string;
  periods?: ICreateProjectOfferPeriodRequest[];
  description?: string;
}

export interface ICreateProjectOfferPeriodRequest {
  periodName: number;
  offerType?: EnergyPricingOfferTypes;
  startDate: string;
  endDate: string;
  peak?: number;
  shoulder?: number;
  offPeak?: number;
  lretType?: GreenPricingTypes;
  lret?: number;
  sresType?: GreenPricingTypes;
  sres?: number;
  essType?: GreenPricingTypes;
  ess?: number;
  veetType?: GreenPricingTypes;
  veet?: number;
  aeeisType?: GreenPricingTypes;
  aeeis?: number;
  serviceStartDate?: string;
  serviceEndDate?: string;
  description?: string;
}

export interface IUpdateProjectOfferPeriodRequest extends ICreateProjectOfferPeriodRequest {
  id: string;
}

export interface IUpdateProjectOfferRequest extends ICreateProjectOfferRequest {
  id?: string;
}

export interface IDeleteProjectOfferRequest {
  id: string;
  periods: { id: string }[];
}

export class ProjectOffer extends BaseEntity implements IProjectOfferPrimaryResponse {
  id?: string;
  meterIds!: string[];
  retailerId!: string;
  retailer!: Retailer;
  meteringFeePeriod?: FeePeriod;
  meteringFeePrice?: number;
  suppDMAOrRPFeePeriod?: FeePeriod;
  suppDMAOrRPFeePrice?: number;
  serviceFeePeriod?: FeePeriod;
  serviceFeePrice?: number;
  greenBilling?: GreenBillingCalculation;
  discount?: number;
  validityEndDateTime?: string;
  periods!: ProjectOfferPeriod[];
  description?: string;

  static override from<T = ProjectOffer>(raw: any): T {
    return Object.assign(new ProjectOffer(), {
      ...raw,
      periods: sortBy(raw.periods ?? [], (v) => date(v.endDate).valueOf())
    });
  }

  get dailyMeteringFee(): number {
    return calculateDailyPriceByFeePeriod(this.meteringFeePrice, this.meteringFeePeriod);
  }

  get dailySuppDMAOrRPFee(): number {
    return calculateDailyPriceByFeePeriod(this.suppDMAOrRPFeePrice, this.suppDMAOrRPFeePeriod);
  }

  get dailyServiceFee(): number {
    return calculateDailyPriceByFeePeriod(this.serviceFeePrice, this.serviceFeePeriod);
  }
}

export class ProjectOfferPeriod extends BaseEntity implements IUpsertProjectOfferPeriodRequest {
  id!: string;
  periodName!: number;
  offerType?: EnergyPricingOfferTypes;
  startDate!: string;
  endDate!: string;
  peak!: number;
  shoulder!: number;
  offPeak!: number;
  lretType?: GreenPricingTypes;
  lret?: number;
  sresType?: GreenPricingTypes;
  sres?: number;
  essType?: GreenPricingTypes;
  ess?: number;
  veetType?: GreenPricingTypes;
  veet?: number;
  aeeisType?: GreenPricingTypes;
  aeeis?: number;
  description?: string;

  static override from<T = ProjectOfferPeriod>(raw: any): T {
    return Object.assign(new ProjectOfferPeriod(), {
      ...raw,
      peak: raw.peak || 0.0,
      shoulder: raw.shoulder || 0.0,
      offPeak: raw.offPeak || 0.0
    });
  }

  get numOfDays(): number {
    return date(this.endDate).diff(date(this.startDate), 'day') + 1;
  }

  get startYear(): number {
    return date(this.startDate).year();
  }

  get endYear(): number {
    return date(this.endDate).year();
  }

  get daysStart(): number {
    return (
      date(this.startYear === this.endYear ? this.endDate : `${date(this.endDate).year()}-12-31`).diff(
        date(this.startDate),
        'day'
      ) + 1
    );
  }

  get daysEnd(): number {
    return this.startYear === this.endYear
      ? 0
      : date(this.endDate).diff(`${date(this.endYear).year()}-01-01`, 'day') + 1;
  }
}

interface IOperatorResponse {
  id: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  title: string;
}

export interface IProjectHistoryQueryResponse {
  id: string;
  projectId: string;
  status: ProjectStatus;
  createdAt: string;
  operator: IOperatorResponse;
  description?: string;
}

export interface IUpdateProjectReportSettingsRequest {
  reportType: string;
  contactWithId: string;
  companyId: string;
  customizedCompanyName: string;
  meterGroups: MeterGroup[];
}

export interface IProjectReportSettingsQueryResponse {
  id: string;
  projectId: string;
  reportType: string;
  contactWith: User;
  company: Company;
  customizedCompanyName?: string;
  meterGroups: MeterGroup[];
}

export class ProjectReportSettings extends BaseEntity implements IProjectReportSettingsQueryResponse {
  id!: string;
  projectId!: string;
  reportType!: string;
  contactWith!: User;
  company!: Company;
  customizedCompanyName?: string;
  meterGroups!: MeterGroup[];

  static override from<T = ProjectReportSettings>(raw: any): T {
    return Object.assign(new ProjectReportSettings(), {
      ...raw,
      meterGroups: JSON.parse(raw.meterGroups || '[]')
    });
  }
}
