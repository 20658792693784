import { Tab, Tabs, Typography } from '@mui/material';
import { find, map, multiply, sum, sumBy } from 'lodash-es';
import React from 'react';
import { date } from '~/lib/date';
import { SiteClassification } from '~/models/meter';
import BillSection from '~/pages/dashboard/project/EAReport/BillSection';
import { Section, SectionContainer, Title, TitleUnderline } from '~/pages/dashboard/project/EAReport/style';
import { CalculatedProjectOfferPeriodsByName, EAReportGroupedMeter } from './types';

const calculateDiffInPercent = (oldValue: number, newValue: number) => {
  return oldValue ? (newValue - oldValue) / oldValue : 0;
};

const CurrentVsNewBillSection: React.FC<{ groupedMeter: EAReportGroupedMeter; reportType: string; name: string }> = ({
  groupedMeter,
  reportType,
  name
}) => {
  const [currentVsNewTab, setCurrentVsNewTab] = React.useState(0);
  const bestOffer = find(groupedMeter.offers, (offer) => offer.isBest);
  const bestOfferPeriod =
    find(bestOffer?.periods, (period) => period.isBest) || ({} as CalculatedProjectOfferPeriodsByName);
  const divide = reportType === 'ANNUALLY' ? 1 : 1 / 12;
  const isTariff = groupedMeter.meters[0].contract.contractType === SiteClassification.TARIFF;
  const peakEnergy = sumBy(groupedMeter.meters, 'annualData.peakEnergy');
  const offPeakEnergy = sumBy(groupedMeter.meters, 'annualData.offPeakEnergy');
  const shoulderEnergy = sumBy(groupedMeter.meters, 'annualData.shoulderEnergy');

  const calculateEnergyCharge = (energy: number, rate: number) => {
    return (multiply(energy, rate) * (isTariff ? 1 : groupedMeter.lwaDLF * groupedMeter.lwaMLF)) / 100;
  };

  const calculateEnvironmentalCharge = (rate?: number) => {
    return ((rate ?? 0) * groupedMeter.lossFactor * groupedMeter.annualData.totalEnergy) / 100;
  };

  const currentContract = {
    lwaMLF: groupedMeter.lwaMLF,
    lwaDLF: groupedMeter.lwaDLF,
    lossFactor: groupedMeter.lossFactor,
    peakEnergy: peakEnergy * divide,
    offPeakEnergy: offPeakEnergy * divide,
    shoulderEnergy: shoulderEnergy * divide,
    totalEnergy: groupedMeter.annualData.totalEnergy * divide,
    contestableCost: groupedMeter.currentContestableCost * divide,
    energyCharge: {
      isTariff,
      peakRate: groupedMeter.weighedAverageData.peakRate,
      peakSubTotal: calculateEnergyCharge(peakEnergy, groupedMeter.weighedAverageData.peakRate) * divide,
      offPeakRate: groupedMeter.weighedAverageData.offPeakRate,
      offPeakSubTotal: calculateEnergyCharge(offPeakEnergy, groupedMeter.weighedAverageData.offPeakRate) * divide,
      shoulderRate: groupedMeter.weighedAverageData.shoulderRate,
      shoulderSubTotal: calculateEnergyCharge(shoulderEnergy, groupedMeter.weighedAverageData.shoulderRate) * divide,
      subTotal: groupedMeter.currentEnergyCost * divide
    },
    networkCharge: {
      standing: sumBy(groupedMeter.meters, 'annualData.networkStanding') * divide,
      energy: sumBy(groupedMeter.meters, 'annualData.networkChargesEnergy') * divide,
      demand: sumBy(groupedMeter.meters, 'annualData.networkChargesDemand') * divide
    },
    regulatedCharge: {
      participantRate: groupedMeter.weighedAverageData.participantCharge,
      ancillaryRate: groupedMeter.weighedAverageData.ancillaryCharge,
      subTotal: groupedMeter.currentRegulatedCost * divide
    },
    environmentalCharge: {
      lrecRate: groupedMeter.weighedAverageData.lret,
      lrecSubTotal: calculateEnvironmentalCharge(groupedMeter.weighedAverageData.lret) * divide,
      sresRate: groupedMeter.weighedAverageData.sres,
      sresSubTotal: calculateEnvironmentalCharge(groupedMeter.weighedAverageData.sres) * divide,
      veetRate: groupedMeter.weighedAverageData.veet,
      veetSubTotal: calculateEnvironmentalCharge(groupedMeter.weighedAverageData.veet) * divide,
      essRate: groupedMeter.weighedAverageData.ess,
      essSubTotal: calculateEnvironmentalCharge(groupedMeter.weighedAverageData.ess) * divide,
      aeeisRate: groupedMeter.weighedAverageData.aeeis,
      aeeisSubTotal: calculateEnvironmentalCharge(groupedMeter.weighedAverageData.aeeis) * divide,
      subTotal: groupedMeter.currentRenewableCost * divide
    },
    meteringAndRetailerCharge: {
      numOfMeters: sumBy(groupedMeter.meters, 'numOfMeters'),
      meteringChargeRate: groupedMeter.weighedAverageData.annualMeteringCharge * divide,
      meteringCharge: groupedMeter.annualData.meteringCharge * divide,
      retailerFeeRate: groupedMeter.weighedAverageData.annualRetailerFee * divide,
      retailerFee: groupedMeter.annualData.retailerFee * divide,
      subTotal: sum([groupedMeter.annualData.meteringCharge, groupedMeter.annualData.retailerFee]) * divide
    }
  };

  const getNewContract = (startDate: string, yearIndex: number) => {
    const periods = bestOfferPeriod.splitPeriods.filter(
      (p) =>
        date(startDate).add(yearIndex, 'year').valueOf() <= date(p.startDate).valueOf() &&
        date(startDate)
          .add(yearIndex + 1, 'year')
          .valueOf() >= date(p.endDate).valueOf()
    );
    const newContract = {
      lwaMLF: groupedMeter.lwaMLF,
      lwaDLF: groupedMeter.lwaDLF,
      lossFactor: groupedMeter.lossFactor,
      peakEnergy: peakEnergy,
      offPeakEnergy: offPeakEnergy * divide,
      shoulderEnergy: shoulderEnergy * divide,
      totalEnergy: groupedMeter.annualData.totalEnergy * divide,
      contestableCost: sumBy(periods, 'contestableCost') * divide,
      energyCharge: {
        isTariff,
        peakRate: periods[0]?.peak ?? 0,
        peakSubTotal: calculateEnergyCharge(peakEnergy, periods[0].peak) * divide,
        offPeakRate: periods[0]?.offPeak ?? 0,
        offPeakSubTotal: calculateEnergyCharge(offPeakEnergy, periods[0].offPeak) * divide,
        shoulderRate: periods[0]?.shoulder ?? 0,
        shoulderSubTotal: calculateEnergyCharge(shoulderEnergy, periods[0].shoulder) * divide,
        subTotal: sumBy(periods, 'energyCost') * divide
      },
      networkCharge: {
        standing: sumBy(groupedMeter.meters, 'annualData.networkStanding') * divide,
        energy: sumBy(groupedMeter.meters, 'annualData.networkChargesEnergy') * divide,
        demand: sumBy(groupedMeter.meters, 'annualData.networkChargesDemand') * divide
      },
      regulatedCharge: {
        participantRate: groupedMeter.weighedAverageData.participantCharge,
        ancillaryRate: groupedMeter.weighedAverageData.ancillaryCharge,
        subTotal: sumBy(periods, 'regulatedCost') * divide
      },
      environmentalCharge: {
        lrecRate: periods[0].lret ?? 0,
        lrecSubTotal: calculateEnvironmentalCharge(periods[0].lret) * divide,
        sresRate: periods[0].sres ?? 0,
        sresSubTotal: calculateEnvironmentalCharge(periods[0].sres) * divide,
        veetRate: periods[0].veet ?? 0,
        veetSubTotal: calculateEnvironmentalCharge(periods[0].veet) * divide,
        essRate: periods[0].ess ?? 0,
        essSubTotal: calculateEnvironmentalCharge(periods[0].ess) * divide,
        aeeisRate: periods[0].aeeis ?? 0,
        aeeisSubTotal: calculateEnvironmentalCharge(periods[0].aeeis) * divide,
        subTotal: sumBy(periods, 'renewableCost') * divide
      },
      meteringAndRetailerCharge: {
        numOfMeters: sumBy(groupedMeter.meters, 'numOfMeters'),
        meteringChargeRate: sumBy(periods, 'meteringCharge') / sumBy(groupedMeter.meters, 'numOfMeters'),
        meteringCharge: sumBy(periods, 'meteringCharge') * divide,
        retailerFeeRate: sumBy(periods, 'retailerServiceFee') / sumBy(groupedMeter.meters, 'numOfMeters'),
        retailerFee: sumBy(periods, 'retailerServiceFee') * divide,
        subTotal: sum([sumBy(periods, 'meteringCharge'), sumBy(periods, 'retailerServiceFee')]) * divide
      }
    };

    return (
      <BillSection
        data={{
          ...newContract,
          hasDiffValue: true,
          energyCharge: {
            ...newContract.energyCharge,
            diff: {
              peak: newContract.energyCharge.peakSubTotal - currentContract.energyCharge.peakSubTotal,
              offPeak: newContract.energyCharge.offPeakSubTotal - currentContract.energyCharge.offPeakSubTotal,
              shoulder: newContract.energyCharge.shoulderSubTotal - currentContract.energyCharge.shoulderSubTotal,
              subTotal: newContract.energyCharge.subTotal - currentContract.energyCharge.subTotal
            },
            diffInPercent: {
              peak: calculateDiffInPercent(
                currentContract.energyCharge.peakSubTotal,
                newContract.energyCharge.peakSubTotal
              ),
              offPeak: calculateDiffInPercent(
                currentContract.energyCharge.offPeakSubTotal,
                newContract.energyCharge.offPeakSubTotal
              ),
              shoulder: calculateDiffInPercent(
                currentContract.energyCharge.shoulderSubTotal,
                newContract.energyCharge.shoulderSubTotal
              ),
              subTotal: calculateDiffInPercent(currentContract.energyCharge.subTotal, newContract.energyCharge.subTotal)
            }
          },
          environmentalCharge: {
            ...newContract.environmentalCharge,
            diff: {
              lrec: newContract.environmentalCharge.lrecSubTotal - currentContract.environmentalCharge.lrecSubTotal,
              sres: newContract.environmentalCharge.sresSubTotal - currentContract.environmentalCharge.sresSubTotal,
              veet: newContract.environmentalCharge.veetSubTotal - currentContract.environmentalCharge.veetSubTotal,
              ess: newContract.environmentalCharge.essSubTotal - currentContract.environmentalCharge.essSubTotal,
              aeeis: newContract.environmentalCharge.aeeisSubTotal - currentContract.environmentalCharge.aeeisSubTotal,
              subTotal: newContract.environmentalCharge.subTotal - currentContract.environmentalCharge.subTotal
            },
            diffInPercent: {
              lrec: calculateDiffInPercent(
                currentContract.environmentalCharge.lrecSubTotal,
                newContract.environmentalCharge.lrecSubTotal
              ),
              sres: calculateDiffInPercent(
                currentContract.environmentalCharge.sresSubTotal,
                newContract.environmentalCharge.sresSubTotal
              ),
              veet: calculateDiffInPercent(
                currentContract.environmentalCharge.veetSubTotal,
                newContract.environmentalCharge.veetSubTotal
              ),
              ess: calculateDiffInPercent(
                currentContract.environmentalCharge.essSubTotal,
                newContract.environmentalCharge.essSubTotal
              ),
              aeeis: calculateDiffInPercent(
                currentContract.environmentalCharge.aeeisSubTotal,
                newContract.environmentalCharge.aeeisSubTotal
              ),
              subTotal: calculateDiffInPercent(
                currentContract.environmentalCharge.subTotal,
                newContract.environmentalCharge.subTotal
              )
            }
          },
          meteringAndRetailerCharge: {
            ...newContract.meteringAndRetailerCharge,
            diff: {
              meteringCharge:
                newContract.meteringAndRetailerCharge.meteringCharge -
                currentContract.meteringAndRetailerCharge.meteringCharge,
              retailerFee:
                newContract.meteringAndRetailerCharge.retailerFee -
                currentContract.meteringAndRetailerCharge.retailerFee,
              subTotal:
                newContract.meteringAndRetailerCharge.subTotal - currentContract.meteringAndRetailerCharge.subTotal
            },
            diffInPercent: {
              meteringCharge: calculateDiffInPercent(
                currentContract.meteringAndRetailerCharge.meteringCharge,
                newContract.meteringAndRetailerCharge.meteringCharge
              ),
              retailerFee: calculateDiffInPercent(
                currentContract.meteringAndRetailerCharge.retailerFee,
                newContract.meteringAndRetailerCharge.retailerFee
              ),
              subTotal: calculateDiffInPercent(
                currentContract.meteringAndRetailerCharge.subTotal,
                newContract.meteringAndRetailerCharge.subTotal
              )
            }
          },
          diff: newContract.contestableCost - currentContract.contestableCost,
          diffInPercent: calculateDiffInPercent(currentContract.contestableCost, newContract.contestableCost)
        }}
      />
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentVsNewTab(newValue);
  };

  return (
    <>
      <Section>
        <SectionContainer sx={{ textAlign: 'center' }}>
          <Title variant="h4" gutterBottom>
            Current Contract vs New Contract
          </Title>
          <Title variant="h5">{reportType}</Title>
          <TitleUnderline />
          <Typography variant="h5">{name || map(groupedMeter.meters, 'meterNumber').join(', ')}</Typography>
          <Tabs onChange={handleChange} value={currentVsNewTab} selectionFollowsFocus>
            <Tab
              label={<Title variant="h6">Current Contract</Title>}
              id="current-vs-new-tab-0"
              aria-controls="current-vs-new-tabpanel-0"
            />
            {[...Array(Math.floor(bestOfferPeriod.periodName / 12))].map((_, index) => (
              <Tab
                key={`current-vs-new-tab-${index + 1}`}
                id={`current-vs-new-tab-${index + 1}`}
                label={<Title variant="h6">Next {(index + 1) * 12} Months</Title>}
                aria-controls={`current-vs-new-tabpanel-${index + 1}`}
              />
            ))}
          </Tabs>
        </SectionContainer>
      </Section>

      <div
        role="tabpanel"
        hidden={currentVsNewTab !== 0}
        id={`current-vs-new-tabpanel-0`}
        aria-labelledby={`current-vs-new-tab-0`}
      >
        {currentVsNewTab === 0 && <BillSection data={currentContract} />}
      </div>

      {[...Array(Math.floor(bestOfferPeriod.periodName / 12))].map((_, index) => (
        <div
          role="tabpanel"
          key={`current-vs-new-tabpanel-${index + 1}`}
          id={`current-vs-new-tabpanel-${index + 1}`}
          hidden={currentVsNewTab !== index + 1}
          aria-labelledby={`current-vs-new-tab-${index + 1}`}
        >
          {currentVsNewTab === index + 1 && getNewContract(bestOfferPeriod.splitPeriods[0].startDate, index)}
        </div>
      ))}
    </>
  );
};

export default CurrentVsNewBillSection;
