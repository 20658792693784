import { BaseEntity } from '~/lib/model/base';
import { IAction, RefObj, RefType } from './common';
import { IRoleOutlineResponse } from './role';

export type Role =
  | 'ROOT_ADMIN'
  | 'SYSTEM_ADMIN'
  | 'DIRECTOR'
  | 'SALES_MANAGER'
  | 'SALES'
  | 'BDM'
  | 'EMC_MANAGER'
  | 'FINANCE_MANAGER'
  | 'EMC'
  | 'MARKETING'
  | 'SERVICE_DELIVERY'
  | 'ACCOUNTANT';

export type Permission = `${
  | 'user'
  | 'role'
  | 'retailer'
  | 'nmi'
  | 'mirn'
  | 'company'
  | 'council'
  | 'association'
  | 'project'
  | 'lookup'}:${'create' | 'list' | 'view' | 'edit' | 'delete'}`;

export enum UserStatusType {
  CONFIRMED = 'CONFIRMED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD'
}

export interface IUserAction extends IAction {
  email: boolean;
}

export interface IUser {
  id: string;
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  roles: IRoleOutlineResponse[];
  permissions?: Permission[];
  status: UserStatusType;
  action: IUserAction;
}

export class User extends BaseEntity implements IUser {
  id!: string;
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  roles!: IRoleOutlineResponse[];
  permissions?: Permission[];
  status!: UserStatusType;
  action!: IUserAction;

  get fullName(): string {
    const { firstName = '', middleName = '', lastName = '' } = this;
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
  }

  get roleNames(): string[] {
    return this.roles.map((it) => it.displayName);
  }

  hasRole(role: Role): boolean {
    return !!this.roles.find((it) => it.name === role);
  }
}

export type IUserUpdateRequest = Pick<IUser, 'firstName' | 'middleName' | 'lastName' | 'phoneNumber'>;

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export interface IUpdateMyProfileRequest {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  preferredName?: string;
  title?: string;
  gender?: Gender;
  phoneNumber?: string;
  description?: string;
}

export interface IUserPrimaryResponse {
  id: string;
  email: string;
  roles: IRoleOutlineResponse[];
  permissions?: string[];
  firstName?: string;
  middleName?: string;
  lastName?: string;
  preferredName?: string;
  title?: string;
  gender?: Gender;
  phoneNumber?: string;
  status?: UserStatusType;
  enabled?: boolean;
  description?: string;
}

export type IUserCreateRequest = Required<Pick<IUser, 'email' | 'roles'>> &
  Pick<IUser, 'firstName' | 'middleName' | 'lastName' | 'phoneNumber'>;

export interface IUserUpdateRoleRequset {
  roles: string[];
}

export enum NotificationStatus {
  NEW = 'NEW',
  VIEWED = 'VIEWED'
}

export interface INotificationQueryResponse {
  id: string;
  status: NotificationStatus;
  refType: RefType;
  refId: string;
  refObj: RefObj;
  content: string;
  createdAt: string;
  createdBy: IUser;
}

export enum TaskStatus {
  PENDING = 'PENDING',
  DONE = 'DONE'
}

export enum TaskAudience {
  USER = 'USER',
  ROLE = 'ROLE'
}

export interface ITaskAssignmentResponse {
  id: string;
  assignedUser: string;
  assignedRole: string;
  status: TaskStatus;
  doneDateTime: string;
  description: string;
}

export interface ITaskQueryResponse {
  id: string;
  refType: RefType;
  refId: string;
  refObj: RefObj;
  type: string;
  status: TaskStatus;
  audience: TaskAudience;
  assignments: ITaskAssignmentResponse[];
  content: string;
  startDateTime: string;
  dueDateTime: string;
  doneDateTime: string;
  createdAt: string;
  createdBy: IUser;
  updatedAt: string;
  updatedBy: IUser;
}
