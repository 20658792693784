import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { sum } from 'lodash-es';
import React from 'react';
import NumberText from '~/components/NumberText';
import { Section, SectionContainer, StyledTableCell, StyledTableRow } from '~/pages/dashboard/project/EAReport/style';

export interface BillData {
  hasDiffValue?: boolean;
  lwaMLF: number;
  lwaDLF: number;
  lossFactor: number;
  peakEnergy: number;
  offPeakEnergy: number;
  shoulderEnergy: number;
  totalEnergy: number;
  contestableCost: number;
  energyCharge: {
    isTariff: boolean;
    peakRate: number;
    peakSubTotal: number;
    offPeakRate: number;
    offPeakSubTotal: number;
    shoulderRate: number;
    shoulderSubTotal: number;
    subTotal: number;
    diff?: {
      peak: number;
      offPeak: number;
      shoulder: number;
      subTotal: number;
    };
    diffInPercent?: {
      peak: number;
      offPeak: number;
      shoulder: number;
      subTotal: number;
    };
  };
  networkCharge: {
    standing: number;
    energy: number;
    demand: number;
  };
  regulatedCharge: {
    participantRate: number;
    ancillaryRate: number;
    subTotal: number;
  };
  environmentalCharge: {
    lrecRate: number;
    lrecSubTotal: number;
    sresRate: number;
    sresSubTotal: number;
    veetRate: number;
    veetSubTotal: number;
    essRate: number;
    essSubTotal: number;
    aeeisRate: number;
    aeeisSubTotal: number;
    subTotal: number;
    diff?: {
      lrec: number;
      sres: number;
      veet: number;
      ess: number;
      aeeis: number;
      subTotal: number;
    };
    diffInPercent?: {
      lrec: number;
      sres: number;
      veet: number;
      ess: number;
      aeeis: number;
      subTotal: number;
    };
  };
  meteringAndRetailerCharge: {
    numOfMeters: number;
    meteringChargeRate: number;
    meteringCharge: number;
    retailerFeeRate: number;
    retailerFee: number;
    subTotal: number;
    diff?: {
      meteringCharge: number;
      retailerFee: number;
      subTotal: number;
    };
    diffInPercent?: {
      meteringCharge: number;
      retailerFee: number;
      subTotal: number;
    };
  };
  diff?: number;
  diffInPercent?: number;
}

const DiffCharge: React.FC<{ diffValue?: number; diffValueInPercent?: number }> = ({
  diffValue,
  diffValueInPercent
}) => (
  <>
    <StyledTableCell
      sx={{
        bgcolor: (diffValue ?? 0) <= 0 ? '#a5d6a7' : '#ef9a9a',
        color: (diffValue ?? 0) <= 0 ? '#1b5e20' : '#c62828'
      }}
    >
      $<NumberText value={diffValue} precision={0} na="0" />
    </StyledTableCell>
    <StyledTableCell
      sx={{
        bgcolor: (diffValue ?? 0) <= 0 ? '#a5d6a7' : '#ef9a9a',
        color: (diffValue ?? 0) <= 0 ? '#1b5e20' : '#c62828'
      }}
    >
      <NumberText value={(diffValueInPercent ?? 0) * 100} precision={2} na="-" />%
    </StyledTableCell>
  </>
);

const BillSection: React.FC<{ data: BillData }> = ({ data }) => {
  return (
    <Section>
      <SectionContainer>
        <TableContainer sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={data.hasDiffValue === true ? 8 : 6} sx={{ textAlign: 'left !important' }}>
                  Energy Charges
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: 'brand.main' }}>
                <StyledTableCell sx={{ color: 'common.white' }}>Energy Charges</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Quantity Unit</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Rate Unit</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>MLF</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>DLF</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Amount$(Ex GST)</StyledTableCell>
                {data.hasDiffValue === true && (
                  <>
                    <StyledTableCell sx={{ color: 'common.white' }}>$ Change</StyledTableCell>
                    <StyledTableCell sx={{ color: 'common.white' }}>% Change</StyledTableCell>
                  </>
                )}
              </TableRow>
              {data.energyCharge.peakRate > 0 && (
                <StyledTableRow>
                  <StyledTableCell>Peak</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.peakEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.energyCharge.peakRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lwaMLF} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lwaDLF} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.energyCharge.peakSubTotal} precision={0} />
                  </StyledTableCell>
                  {data.hasDiffValue === true && (
                    <DiffCharge
                      diffValue={data.energyCharge.diff?.peak}
                      diffValueInPercent={data.energyCharge.diffInPercent?.peak}
                    />
                  )}
                </StyledTableRow>
              )}

              {data.energyCharge.shoulderRate > 0 && (
                <StyledTableRow>
                  <StyledTableCell>Shoulder</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.shoulderEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.energyCharge.shoulderRate} precision={5} na="-" /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lwaMLF} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lwaDLF} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.energyCharge.shoulderSubTotal} precision={0} />
                  </StyledTableCell>
                  {data.hasDiffValue === true && (
                    <DiffCharge
                      diffValue={data.energyCharge.diff?.shoulder}
                      diffValueInPercent={data.energyCharge.diffInPercent?.shoulder}
                    />
                  )}
                </StyledTableRow>
              )}

              {data.energyCharge.offPeakRate > 0 && (
                <StyledTableRow>
                  <StyledTableCell>Off-Peak</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.offPeakEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.energyCharge.offPeakRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lwaMLF} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lwaDLF} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.energyCharge.offPeakSubTotal} precision={0} />
                  </StyledTableCell>
                  {data.hasDiffValue === true && (
                    <DiffCharge
                      diffValue={data.energyCharge.diff?.offPeak}
                      diffValueInPercent={data.energyCharge.diffInPercent?.offPeak}
                    />
                  )}
                </StyledTableRow>
              )}

              <StyledTableRow>
                <StyledTableCell>Sub-total</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.energyCharge.subTotal} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={data.energyCharge.diff?.subTotal}
                    diffValueInPercent={data.energyCharge.diffInPercent?.subTotal}
                  />
                )}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>GST</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.energyCharge.subTotal * 0.1} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={(data.energyCharge.diff?.subTotal ?? 0) * 0.1}
                    diffValueInPercent={data.energyCharge.diffInPercent?.subTotal}
                  />
                )}
              </StyledTableRow>
              <TableRow sx={{ bgcolor: 'grey.A700' }}>
                <StyledTableCell sx={{ color: 'common.white' }}>Total Energy Charges</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>
                  $<NumberText value={data.energyCharge.subTotal * 1.1} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={(data.energyCharge.diff?.subTotal ?? 0) * 1.1}
                    diffValueInPercent={data.energyCharge.diffInPercent?.subTotal}
                  />
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {sum([data.networkCharge.standing, data.networkCharge.energy, data.networkCharge.demand]) > 0 && (
          <TableContainer sx={{ mb: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={6} sx={{ textAlign: 'left !important' }}>
                    Network Charges
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: 'brand.main' }}>
                  <StyledTableCell sx={{ color: 'common.white' }}>Network Charges</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white' }}>Amount$(Ex GST)</StyledTableCell>
                </TableRow>
                <StyledTableRow>
                  <StyledTableCell>Network - Standing</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell>
                    $
                    <NumberText value={data.networkCharge.standing} precision={0} na="-" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Network - Energy</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell>
                    $
                    <NumberText value={data.networkCharge.energy} precision={0} na="-" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Network - Demand</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell>
                    $
                    <NumberText value={data.networkCharge.demand} precision={0} na="-" />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Sub-total</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell>
                    $
                    <NumberText
                      value={sum([data.networkCharge.standing, data.networkCharge.energy, data.networkCharge.demand])}
                      precision={0}
                    />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>GST</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell>
                    $
                    <NumberText
                      value={
                        sum([data.networkCharge.standing, data.networkCharge.energy, data.networkCharge.demand]) * 0.1
                      }
                      precision={0}
                    />
                  </StyledTableCell>
                </StyledTableRow>
                <TableRow sx={{ bgcolor: 'grey.A700' }}>
                  <StyledTableCell sx={{ color: 'common.white' }}>Total Energy Charges</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white' }}>
                    $
                    <NumberText
                      value={
                        sum([data.networkCharge.standing, data.networkCharge.energy, data.networkCharge.demand]) * 1.1
                      }
                      precision={0}
                    />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {data.regulatedCharge.subTotal > 0 && (
          <TableContainer sx={{ mb: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={6} sx={{ textAlign: 'left !important' }}>
                    Regulated Charges
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: 'brand.main' }}>
                  <StyledTableCell sx={{ color: 'common.white' }}>Regulated Charges</StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white' }}>Quantity Unit</StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white' }}>Rate Unit</StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white' }}></StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white' }}>Loss Factor</StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white' }}>Amount$(Ex GST)</StyledTableCell>
                </TableRow>
                <StyledTableRow>
                  <StyledTableCell>AEMO Participant Charge</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.totalEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.regulatedCharge.participantRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lossFactor} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $
                    <NumberText
                      value={(data.regulatedCharge.participantRate * data.totalEnergy * data.lossFactor) / 100}
                      precision={0}
                    />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>AEMO Ancillary Charge</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.totalEnergy} precision={2} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.regulatedCharge.ancillaryRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lossFactor} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $
                    <NumberText
                      value={(data.regulatedCharge.ancillaryRate * data.totalEnergy * data.lossFactor) / 100}
                      precision={0}
                    />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Sub-total</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.regulatedCharge.subTotal} precision={0} />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>GST</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.regulatedCharge.subTotal * 0.1} precision={0} />
                  </StyledTableCell>
                </StyledTableRow>
                <TableRow sx={{ bgcolor: 'grey.A700' }}>
                  <StyledTableCell sx={{ color: 'common.white' }}>Total Regulated Charges</StyledTableCell>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white' }}>
                    $<NumberText value={data.regulatedCharge.subTotal * 1.1} precision={0} />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TableContainer sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={data.hasDiffValue === true ? 8 : 6} sx={{ textAlign: 'left !important' }}>
                  Environmental Charges
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: 'brand.main' }}>
                <StyledTableCell sx={{ color: 'common.white' }}>Environmental Charges</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Quantity Unit</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Rate Unit</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}></StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Loss Factor</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Amount$(Ex GST)</StyledTableCell>
                {data.hasDiffValue === true && (
                  <>
                    <StyledTableCell sx={{ color: 'common.white' }}>$ Change</StyledTableCell>
                    <StyledTableCell sx={{ color: 'common.white' }}>% Change</StyledTableCell>
                  </>
                )}
              </TableRow>
              {data.environmentalCharge.lrecRate > 0 && (
                <StyledTableRow>
                  <StyledTableCell>LREC Charge</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.totalEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.environmentalCharge.lrecRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lossFactor} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.environmentalCharge.lrecSubTotal} precision={0} />
                  </StyledTableCell>
                  {data.hasDiffValue === true && (
                    <DiffCharge
                      diffValue={data.environmentalCharge.diff?.lrec}
                      diffValueInPercent={data.environmentalCharge.diffInPercent?.lrec}
                    />
                  )}
                </StyledTableRow>
              )}

              {data.environmentalCharge.sresRate > 0 && (
                <StyledTableRow>
                  <StyledTableCell>SREC Charge</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.totalEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.environmentalCharge.sresRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lossFactor} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.environmentalCharge.sresSubTotal} precision={0} />
                  </StyledTableCell>
                  {data.hasDiffValue === true && (
                    <DiffCharge
                      diffValue={data.environmentalCharge.diff?.sres}
                      diffValueInPercent={data.environmentalCharge.diffInPercent?.sres}
                    />
                  )}
                </StyledTableRow>
              )}

              {data.environmentalCharge.veetRate > 0 && (
                <StyledTableRow>
                  <StyledTableCell>VEEC Charge</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.totalEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.environmentalCharge.veetRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lossFactor} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.environmentalCharge.veetSubTotal} precision={0} />
                  </StyledTableCell>
                  {data.hasDiffValue === true && (
                    <DiffCharge
                      diffValue={data.environmentalCharge.diff?.veet}
                      diffValueInPercent={data.environmentalCharge.diffInPercent?.veet}
                    />
                  )}
                </StyledTableRow>
              )}

              {data.environmentalCharge.essRate > 0 && (
                <StyledTableRow>
                  <StyledTableCell>ESS Charge</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.totalEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.environmentalCharge.essRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lossFactor} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.environmentalCharge.essSubTotal} precision={0} />
                  </StyledTableCell>
                  {data.hasDiffValue === true && (
                    <DiffCharge
                      diffValue={data.environmentalCharge.diff?.ess}
                      diffValueInPercent={data.environmentalCharge.diffInPercent?.ess}
                    />
                  )}
                </StyledTableRow>
              )}

              {data.environmentalCharge.aeeisRate > 0 && (
                <StyledTableRow>
                  <StyledTableCell>AEEIS Charge</StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.totalEnergy} precision={0} /> kWh
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.environmentalCharge.aeeisRate} precision={5} /> c/kWh
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <NumberText value={data.lossFactor} precision={5} />
                  </StyledTableCell>
                  <StyledTableCell>
                    $<NumberText value={data.environmentalCharge.aeeisSubTotal} precision={0} />
                  </StyledTableCell>
                  {data.hasDiffValue === true && (
                    <DiffCharge
                      diffValue={data.environmentalCharge.diff?.aeeis}
                      diffValueInPercent={data.environmentalCharge.diffInPercent?.aeeis}
                    />
                  )}
                </StyledTableRow>
              )}

              <StyledTableRow>
                <StyledTableCell>Sub-total</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.environmentalCharge.subTotal} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={data.environmentalCharge.diff?.subTotal}
                    diffValueInPercent={data.environmentalCharge.diffInPercent?.subTotal}
                  />
                )}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>GST</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.environmentalCharge.subTotal * 0.1} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={(data.environmentalCharge.diff?.subTotal ?? 0) * 0.1}
                    diffValueInPercent={data.environmentalCharge.diffInPercent?.subTotal}
                  />
                )}
              </StyledTableRow>
              <TableRow sx={{ bgcolor: 'grey.A700' }}>
                <StyledTableCell sx={{ color: 'common.white' }}>Total Environmental Charges</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>
                  $<NumberText value={data.environmentalCharge.subTotal * 1.1} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={(data.environmentalCharge.diff?.subTotal ?? 0) * 1.1}
                    diffValueInPercent={data.environmentalCharge.diffInPercent?.subTotal}
                  />
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={data.hasDiffValue === true ? 8 : 6} sx={{ textAlign: 'left !important' }}>
                  Metering And Retailer Charges
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: 'brand.main' }}>
                <StyledTableCell sx={{ color: 'common.white' }}>Metering And Retailer Charges</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Quantity Unit</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Rate</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Quantity</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Unit</StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>Amount$(Ex GST)</StyledTableCell>
                {data.hasDiffValue === true && (
                  <>
                    <StyledTableCell sx={{ color: 'common.white' }}>$ Change</StyledTableCell>
                    <StyledTableCell sx={{ color: 'common.white' }}>% Change</StyledTableCell>
                  </>
                )}
              </TableRow>
              <StyledTableRow>
                <StyledTableCell>Metering Charge</StyledTableCell>
                <StyledTableCell>{data.meteringAndRetailerCharge.numOfMeters} Meter(s)</StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.meteringAndRetailerCharge.meteringChargeRate} precision={2} />
                </StyledTableCell>
                <StyledTableCell>1</StyledTableCell>
                <StyledTableCell>Years</StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.meteringAndRetailerCharge.meteringCharge} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={data.meteringAndRetailerCharge.diff?.meteringCharge}
                    diffValueInPercent={data.meteringAndRetailerCharge.diffInPercent?.meteringCharge}
                  />
                )}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Retailer Fee</StyledTableCell>
                <StyledTableCell>{data.meteringAndRetailerCharge.numOfMeters} Meter(s)</StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.meteringAndRetailerCharge.retailerFeeRate} precision={2} />
                </StyledTableCell>
                <StyledTableCell>1</StyledTableCell>
                <StyledTableCell>Years</StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.meteringAndRetailerCharge.retailerFee} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={data.meteringAndRetailerCharge.diff?.retailerFee}
                    diffValueInPercent={data.meteringAndRetailerCharge.diffInPercent?.retailerFee}
                  />
                )}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Sub-total</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.meteringAndRetailerCharge.subTotal} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={data.meteringAndRetailerCharge.diff?.subTotal}
                    diffValueInPercent={data.meteringAndRetailerCharge.diffInPercent?.subTotal}
                  />
                )}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>GST</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell>
                  $<NumberText value={data.meteringAndRetailerCharge.subTotal * 0.1} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={(data.meteringAndRetailerCharge.diff?.subTotal ?? 0) * 0.1}
                    diffValueInPercent={data.meteringAndRetailerCharge.diffInPercent?.subTotal}
                  />
                )}
              </StyledTableRow>
              <TableRow sx={{ bgcolor: 'grey.A700' }}>
                <StyledTableCell sx={{ color: 'common.white' }}>Total Energy Charges</StyledTableCell>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell sx={{ color: 'common.white' }}>
                  $<NumberText value={data.meteringAndRetailerCharge.subTotal * 1.1} precision={0} />
                </StyledTableCell>
                {data.hasDiffValue === true && (
                  <DiffCharge
                    diffValue={(data.meteringAndRetailerCharge.diff?.subTotal ?? 0) * 1.1}
                    diffValueInPercent={data.meteringAndRetailerCharge.diffInPercent?.subTotal}
                  />
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={6} sx={{ textAlign: 'left !important' }}>
                  TOTAL CHARGES
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: 'brand.main' }}>
                <StyledTableCell sx={{ textAlign: 'left !important' }}>Sub-Total</StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'right !important' }}>
                  $<NumberText value={data.contestableCost} precision={0} />
                </StyledTableCell>
              </TableRow>
              <TableRow sx={{ bgcolor: 'brand.main' }}>
                <StyledTableCell sx={{ textAlign: 'left !important' }}>GST</StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'right !important' }}>
                  $<NumberText value={data.contestableCost * 0.1} precision={0} />
                </StyledTableCell>
              </TableRow>
              <TableRow sx={{ bgcolor: 'brand.main' }}>
                <StyledTableCell sx={{ textAlign: 'left !important', fontWeight: 'bold' }}>Total</StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'right !important', fontWeight: 'bold' }}>
                  $<NumberText value={data.contestableCost * 1.1} precision={0} />
                </StyledTableCell>
              </TableRow>
              {data.hasDiffValue === true && (
                <>
                  <TableRow
                    sx={{
                      bgcolor: (data.diff ?? 0) <= 0 ? '#a5d6a7' : '#ef9a9a',
                      color: (data.diff ?? 0) <= 0 ? '#1b5e20' : '#c62828'
                    }}
                  >
                    <StyledTableCell sx={{ textAlign: 'left !important', fontWeight: 'bold' }}>
                      $ Charge
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'right !important', fontWeight: 'bold' }}>
                      $<NumberText value={(data.diff ?? 0) * 1.1} precision={0} />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      bgcolor: (data.diff ?? 0) <= 0 ? '#a5d6a7' : '#ef9a9a',
                      color: (data.diff ?? 0) <= 0 ? '#1b5e20' : '#c62828'
                    }}
                  >
                    <StyledTableCell sx={{ textAlign: 'left !important', fontWeight: 'bold' }}>
                      % Charge
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'right !important', fontWeight: 'bold' }}>
                      <NumberText value={(data.diffInPercent ?? 0) * 100} precision={0} />%
                    </StyledTableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </SectionContainer>
    </Section>
  );
};

export default BillSection;
