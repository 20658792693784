import { invert } from 'lodash-es';

const READABLE_INFO = new Map<any, any>();

export const addReadableInfo = <T>(enumObj: T, readableInfo: Record<keyof T, string>): void => {
  READABLE_INFO.set(enumObj, readableInfo);
};

export const getReadableInfoObj = <T>(enumObj: T): Record<string, keyof T> => {
  const info = READABLE_INFO.get(enumObj);
  return invert(info) as any;
};

export const getReadableInfo = <T>(enumObj: T, key: keyof T): string => {
  return READABLE_INFO.get(enumObj)[key];
};
