import { BaseError } from '~/lib/error/base';

export class CompleteNewPasswordFailedError extends BaseError {
  constructor(message?: string) {
    super({
      code: 'COMPLETE_NEW_PASSWORD_FAILED',
      message: message ?? 'Complete new password failed'
    });
  }
}
