import { GridValueFormatterParams } from '@mui/x-data-grid';
import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_API_DATE_TIME_FORMAT,
  DEFAULT_UI_DATE_FORMAT,
  DEFAULT_UI_DATE_TIME_FORMAT
} from '~/constants/date';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);

export type DateType = dayjs.Dayjs | string | number | Date | null | undefined;

export const date = dayjs;

export const formatDate = (dateValue: DateType, format: string): string => date(dateValue).format(format);

export const formatApiDate = (dateValue: DateType): string => formatDate(dateValue, DEFAULT_API_DATE_FORMAT);

export const formatApiDateTime = (dateValue: DateType): string => formatDate(dateValue, DEFAULT_API_DATE_TIME_FORMAT);

export const formatUiDate = (dateValue: DateType): string | null =>
  dateValue ? formatDate(dateValue, DEFAULT_UI_DATE_FORMAT) : null;

export const formatUiDateTime = (dateValue: DateType): string => formatDate(dateValue, DEFAULT_UI_DATE_TIME_FORMAT);

export const dataGridDateFormatter = ({ value }: GridValueFormatterParams): string => formatUiDate(value) ?? '-';

export const dataGridRelativeDateFormatter = ({ value }: GridValueFormatterParams): string =>
  value === undefined || value === null ? '-' : formatDateRelatively(value);

export const isValidDate = (date: Date): boolean => date.toString() !== 'Invalid Date';

export const formatDateRelatively = (dateValue: DateType): string => {
  const dateTime = date(dateValue);
  if (date().diff(dateTime, 'days') <= 5) {
    return dateTime.fromNow();
  }
  return formatUiDateTime(dateTime);
};

export const isDateBetween = (day: DateType, startDate: DateType, endDate: DateType): boolean => {
  const startDateScope = date(startDate);
  const endDateScope = date(endDate);
  return date(day).isBetween(startDateScope.startOf('day'), endDateScope.endOf('day'), null, '[]');
};

export const isPastDate = (day: DateType): boolean => {
  return date().startOf('day').valueOf() > date(day).valueOf();
};

export const isFutureDate = (day: DateType): boolean => {
  return date().endOf('day').valueOf() < date(day).valueOf();
};

export const getDateDiff = (date1: string, date2: string, unit: QUnitType | OpUnitType = 'day'): number =>
  dayjs(date1).diff(dayjs(date2), unit) + 1;

export const getEarlierDate = (date1: string, date2?: string) => {
  return date(date1).valueOf() >= date(date2).valueOf() ? date(date2) : date(date1);
};

export const getLaterDate = (date1: string, date2?: string) => {
  return date(date1).valueOf() >= date(date2).valueOf() ? date(date1) : date(date2);
};

export const validateDate = (date1: string, format: string) => {
  return date(date1, format).format(format) === date1;
};
