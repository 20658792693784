import { negate } from 'lodash-es';

export const not = negate;

export const isUndefined = (value: unknown): value is undefined => value === undefined;
export const isNotUndefined = not(isUndefined);

export const isNull = (value: unknown): value is null => value === null;
export const isNotNull = not(isNull);

export const isNullOrUndefined = (value: unknown): value is null | undefined => value === null || value === undefined;
export const isNotNullOrUndefined = not(isNullOrUndefined);

export const isEmptyFormValue = (value: unknown) => isNullOrUndefined(value) || value === '';
export const isNotEmptyFormValue = not(isEmptyFormValue);
